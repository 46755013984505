import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row no-gutters" }
const _hoisted_2 = {
  key: 0,
  class: "col-2"
}
const _hoisted_3 = { class: "col-10 d-flex align-items-center px-3 pt-2" }
const _hoisted_4 = { class: "w-100 position-relative" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_aspect = _resolveComponent("b-aspect")!
  const _component_b_link = _resolveComponent("b-link")!
  const _component_b_list_group_item = _resolveComponent("b-list-group-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_list_group_item, { class: "p-0 mb-2 border-0 bg-light border-bottom" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.imageExist)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", {
                  style: _normalizeStyle(_ctx.style),
                  class: "rounded-left"
                }, [
                  _createVNode(_component_b_aspect, { aspect: _ctx.aspect }, null, 8, ["aspect"])
                ], 4)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_b_link, {
                href: "#",
                to: { name: 'Article', params: { id: _ctx.article.id } },
                class: "text-dark stretched-link"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h5", null, _toDisplayString(_ctx.title), 1)
                ], undefined, true),
                _: 1
              }, 8, ["to"]),
              _createElementVNode("p", {
                class: "text-muted content-size",
                innerHTML: _ctx.extractContent(_ctx.article.content)
              }, null, 8, _hoisted_5)
            ])
          ])
        ])
      ], undefined, true),
      _: 1
    })
  ]))
}