import { Module } from 'vuex'
import { RootState } from '../index'
import { Credentials } from '@/types/modules/forms'
import api from '@/api'
import cookie from 'js-cookie'
import router from '@/router'

export interface AuthState {
  loggedIn: boolean;
  user: object | null;
  errorCode: number;
}

const auth: Module<AuthState, RootState> = {
  namespaced: true,

  state: {
    loggedIn: false,
    user: null,
    errorCode: 0
  },

  getters: {},

  mutations: {
    loggedIn: (state) => {
      state.loggedIn = true
    },

    loggedOut: (state) => {
      state.loggedIn = false
      state.user = null
    },

    setUser: (state, payload) => {
      state.user = payload
    },

    error: (state, payload: number) => {
      state.errorCode = payload
    }
  },

  actions: {
    // Vérifie si l'utilisateur est connecté
    checkSession: ({ commit }) => {
      return api.session.checkSession()
        .then(response => response.data)
        .then(data => {
          if (Object.keys(data.results).length > 0) {
            commit('loggedIn')
            commit('setUser', data.results)
          } else {
            commit('loggedOut')
          }
        })
    },

    login: ({ commit }, payload: Credentials) => {
      return api.session.login(payload)
        .then(response => response.data)
        .then(data => {
          if (data.status) {
            commit('loggedIn')
            commit('setUser', data.results)
          }
          return data
        })
    },

    logout: ({ dispatch }) => {
      api.session.logout()
        .finally(() => {
          dispatch('checkSession')
          cookie.remove('PHPSESSID')
          router.push({ name: 'Login' })
        })
    },

    updateSession: ({ commit }) => {
      return api.user.updateSession()
        .then(response => response.data)
        .then(data => {
          if (data.status) {
            commit('setUser', data.results)
          }
        })
    }
  }
}

export default auth
