export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "preRequisite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pré-requis"])},
        "beforeConsult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À consulter avant toute migration"])},
        "breakingChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A lire avant toute mise à jour"])},
        "importantInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations importantes par version"])},
        "evolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evolutions"])},
        "installOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation complete"])},
        "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installeur"])},
        "installNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installeur du serveur de publication"])},
        "docInstall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation d'installation"])},
        "runtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Runtime Windows Desktop .NET"])},
        "lastPatchFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patch "])},
        "serverExpress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveur Express"])},
        "mng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MNG"])}
      },
      "en": {
        "preRequisite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-requisites"])},
        "beforeConsult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To consult before any migration"])},
        "importantInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important information by version"])},
        "evolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evolutions"])},
        "installOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install of"])},
        "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["installer"])},
        "installNav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishing server installer"])},
        "breakingChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To read before any update"])},
        "lastPatchFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last patch for"])},
        "serverExpress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Express Server"])},
        "mng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MNG"])}
      }
    }
  })
}
