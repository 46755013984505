<template>
  <div class="loader">
    <font-awesome-icon :icon="['fad', 'spinner-third']" style="font-size: 2rem" spin />
  </div>
</template>

<script  lang="ts">

import { mapState } from 'vuex'

import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    ...mapState(['family'])
  },

})
</script>

<style scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7); /* Ajoute un fond semi-transparent pour assombrir la page */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
