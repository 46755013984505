import { library } from '@fortawesome/fontawesome-svg-core'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Solid
import {
  faArrowLeft as fasArrowLeft,
  faArrowRight as fasArrowRight,
  faBuilding as fasBuilding,
  faCalendarAlt as fasCalendarAlt,
  faCheck as fasCheck,
  faChevronDown as fasChevronDown,
  faCodeBranch as fasCodeBranch,
  faCog as fasCog,
  faDownload as fasDownload,
  faEnvelope as fasEnvelope,
  faEye as fasEye,
  faEyeSlash as fasEyeSlash,
  faFile as fasFile,
  faFileAlt as fasFileAlt,
  faFolder as fasFolder,
  faFolderPlus as fasFolderPlus,
  faHome as fasHome,
  faInfoCircle as fasInfoCircle,
  faLanguage as fasLanguage,
  faLockAlt as fasLockAlt,
  faNewspaper as fasNewspaper,
  faPaperPlane as fasPaperPlane,
  faPlus as fasPlus,
  faRedo as fasRedo,
  faSave as fasSave,
  faSignOutAlt as fasSignOutAlt,
  faSync as fasSync,
  faTimes as fasTimes,
  faTrashAlt as fasTrashAlt,
  faUpload as fasUpload,
  faUsers as fasUsers,
  faUserShield as fasUserShield,
  faUserCircle as fasUserCircle,
  faUser as fasUser,
  faPuzzlePiece as fasPuzzlePiece,
} from '@fortawesome/pro-solid-svg-icons'

// Regular
import {
  faArrowLeft as farArrowLeft,
  faArrowRight as farArrowRight,
  faBuilding as farBuilding,
  faCalendarAlt as farCalendarAlt,
  faCheck as farCheck,
  faChevronDown as farChevronDown,
  faCodeBranch as farCodeBranch,
  faCog as farCog,
  faDownload as farDownload,
  faEnvelope as farEnvelope,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faFile as farFile,
  faFileAlt as farFileAlt,
  faFolder as farFolder,
  faFolderPlus as farFolderPlus,
  faHome as farHome,
  faInfoCircle as farInfoCircle,
  faLanguage as farLanguage,
  faLockAlt as farLockAlt,
  faNewspaper as farNewspaper,
  faPaperPlane as farPaperPlane,
  faPlus as farPlus,
  faRedo as farRedo,
  faSave as farSave,
  faSignOutAlt as farSignOutAlt,
  faSync as farSync,
  faTimes as farTimes,
  faTrashAlt as farTrashAlt,
  faUpload as farUpload,
  faUsers as farUsers,
  faUserShield as farUserShield,
  faUserCircle as farUserCircle,
  faUser as farUser,
  faPuzzlePiece as farPuzzlePiece,
} from '@fortawesome/pro-regular-svg-icons'

// Light
import {
  faArrowLeft as falArrowLeft,
  faArrowRight as falArrowRight,
  faBuilding as falBuilding,
  faCalendarAlt as falCalendarAlt,
  faCheck as falCheck,
  faChevronDown as falChevronDown,
  faCodeBranch as falCodeBranch,
  faCog as falCog,
  faDownload as falDownload,
  faEnvelope as falEnvelope,
  faEye as falEye,
  faEyeSlash as falEyeSlash,
  faFile as falFile,
  faFileAlt as falFileAlt,
  faFolder as falFolder,
  faFolderPlus as falFolderPlus,
  faHome as falHome,
  faInfoCircle as falInfoCircle,
  faLanguage as falLanguage,
  faLockAlt as falLockAlt,
  faNewspaper as falNewspaper,
  faPaperPlane as falPaperPlane,
  faPlus as falPlus,
  faRedo as falRedo,
  faSave as falSave,
  faSignOutAlt as falSignOutAlt,
  faSync as falSync,
  faTimes as falTimes,
  faTrashAlt as falTrashAlt,
  faUpload as falUpload,
  faUsers as falUsers,
  faUserShield as falUserShield,
  faUserCircle as falUserCircle,
  faUser as falUser,
  faPuzzlePiece as falPuzzlePiece,
} from '@fortawesome/pro-light-svg-icons'

// Duotone
import {
  faArrowLeft as fadArrowLeft,
  faArrowRight as fadArrowRight,
  faBuilding as fadBuilding,
  faCalendarAlt as fadCalendarAlt,
  faCheck as fadCheck,
  faChevronDown as fadChevronDown,
  faCodeBranch as fadCodeBranch,
  faCog as fadCog,
  faDownload as fadDownload,
  faEnvelope as fadEnvelope,
  faEye as fadEye,
  faEyeSlash as fadEyeSlash,
  faFile as fadFile,
  faFileAlt as fadFileAlt,
  faFolder as fadFolder,
  faFolderPlus as fadFolderPlus,
  faHome as fadHome,
  faInfoCircle as fadInfoCircle,
  faLanguage as fadLanguage,
  faLockAlt as fadLockAlt,
  faNewspaper as fadNewspaper,
  faPaperPlane as fadPaperPlane,
  faPlus as fadPlus,
  faRedo as fadRedo,
  faSave as fadSave,
  faSignOutAlt as fadSignOutAlt,
  faSync as fadSync,
  faTimes as fadTimes,
  faTrashAlt as fadTrashAlt,
  faUpload as fadUpload,
  faUsers as fadUsers,
  faUserShield as fadUserShield,
  faUserCircle as fadUserCircle,
  faUser as fadUser,
  faSpinnerThird as fadSpinnerThird,
  faPuzzlePiece as fadPuzzlePiece,
} from '@fortawesome/pro-duotone-svg-icons'

// Solid
library.add(
  fasArrowLeft,
  fasLockAlt,
  fasArrowRight,
  fasBuilding,
  fasCalendarAlt,
  fasCheck,
  fasChevronDown,
  fasCodeBranch,
  fasCog,
  fasDownload,
  fasEnvelope,
  fasEye,
  fasEyeSlash,
  fasFile,
  fasFileAlt,
  fasFolder,
  fasFolderPlus,
  fasHome,
  fasInfoCircle,
  fasLanguage,
  fasNewspaper,
  fasPaperPlane,
  fasPlus,
  fasRedo,
  fasSave,
  fasSignOutAlt,
  fasSync,
  fasTimes,
  fasTrashAlt,
  fasUpload,
  fasUsers,
  fasUserShield,
  fasUserCircle,
  fasUser,
  fasPuzzlePiece,
)

// Regular
library.add(
  farArrowLeft,
  farLockAlt,
  farArrowRight,
  farBuilding,
  farCalendarAlt,
  farCheck,
  farChevronDown,
  farCodeBranch,
  farCog,
  farDownload,
  farEnvelope,
  farEye,
  farEyeSlash,
  farFile,
  farFileAlt,
  farFolder,
  farFolderPlus,
  farHome,
  farInfoCircle,
  farLanguage,
  farNewspaper,
  farPaperPlane,
  farPlus,
  farRedo,
  farSave,
  farSignOutAlt,
  farSync,
  farTimes,
  farTrashAlt,
  farUpload,
  farUsers,
  farUserShield,
  farUserCircle,
  farUser,
  farPuzzlePiece,
)

// Light
library.add(
  falArrowLeft,
  falLockAlt,
  falArrowRight,
  falBuilding,
  falCalendarAlt,
  falCheck,
  falChevronDown,
  falCodeBranch,
  falCog,
  falDownload,
  falEnvelope,
  falEye,
  falEyeSlash,
  falFile,
  falFileAlt,
  falFolder,
  falFolderPlus,
  falHome,
  falInfoCircle,
  falLanguage,
  falNewspaper,
  falPaperPlane,
  falPlus,
  falRedo,
  falSave,
  falSignOutAlt,
  falSync,
  falTimes,
  falTrashAlt,
  falUpload,
  falUsers,
  falUserShield,
  falUserCircle,
  falUser,
  falPuzzlePiece,
)

// Duotone
library.add(
  fadArrowLeft,
  fadLockAlt,
  fadArrowRight,
  fadBuilding,
  fadCalendarAlt,
  fadCheck,
  fadChevronDown,
  fadCodeBranch,
  fadCog,
  fadDownload,
  fadEnvelope,
  fadEye,
  fadEyeSlash,
  fadFile,
  fadFileAlt,
  fadFolder,
  fadFolderPlus,
  fadHome,
  fadInfoCircle,
  fadLanguage,
  fadNewspaper,
  fadPaperPlane,
  fadPlus,
  fadRedo,
  fadSave,
  fadSignOutAlt,
  fadSync,
  fadTimes,
  fadTrashAlt,
  fadUpload,
  fadUsers,
  fadUserShield,
  fadUserCircle,
  fadUser,
  fadPuzzlePiece,
  fadSpinnerThird
)

// Vue.component('font-awesome-icon', FontAwesomeIcon)
