export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "blogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devblog"])},
        "subTitre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrouvez ici toutes les informations sur le redéveloppement de SOLU-QIQ."])}
      },
      "en": {
        "blogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devblog"])},
        "subTitre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    }
  })
}
