import Home from '@/components/pages/Home.vue'
import Default from '@/views/Default.vue'
import Patchnote from '@/components/pages/dashboard/Patchnote.vue'
import PatchnoteMycarto from '@/components/pages/dashboard/PatchnoteMyCarto.vue'
import profil from '@/components/pages/dashboard/Profile.vue'
import Mng from '@/components/pages/dashboard/Mng.vue'
import dashboard from '@/components/pages/dashboard/dashboard.vue'
import faq from '@/components/pages/faq.vue'

export default [
  {
    path: '',
    component: Default,
    meta: {
      auth: true,
      admin: false
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
        redirect: { name: 'Home.dashboard' },
        children: [
          {
            path: 'dashboard',
            name: 'Home.dashboard',
            component: dashboard,
            props: true
          },
          {
            path: 'profile',
            name: 'Home.profile',
            component: profil,
            props: true
          },
          {
            path: 'qiq-patchnote',
            name: 'Home.Patchnote',
            meta: {
              product: 'SOLU-QIQ',
            },
            component: Patchnote
          },
          {
            path: 'patchnote',
            name: 'Home.PatchnoteMycarto',
            meta: {
              product: 'myCarto',
            },
            component: PatchnoteMycarto
          },
          {
            path: 'MNG',
            name: 'Home.mng',
            meta: {
              product: 'SOLU-QIQ',
            },
            component: Mng
          },
          {
            path: 'faq',
            name: 'Home.faq',
            component: faq
          },
        ]
      }
    ]
  }
]
