<template>
  <div>
    <loader v-if="loading" />
    <div class="container">
      <div class="row">
        <div class="col-4">
          <div
            v-if="filesInfo"
            class="mt-4"
          >
            <div class="mb-3">
              <div class="badge badge-primary">
                Documentation
              </div>
              <b-list-group>
                <b-list-group-item
                  v-if="filesInfo.documentation.preRequis"
                  button
                  class="d-flex align-items-center justify-content-between"
                  @click="showFile(filesInfo.documentation.preRequis)"
                >
                  {{ $t("preRequisite") }}
                  <div class="badge badge-red ml-2">
                    PDF
                  </div>
                </b-list-group-item>

                <b-list-group-item
                  v-if="filesInfo.documentation.breakingChanges"
                  button
                  class="d-flex align-items-center justify-content-between"
                  @click="showFile(filesInfo.documentation.breakingChanges)"
                >
                  {{ $t("breakingChanges") }}
                  <div class="badge badge-red ml-2">
                    PDF
                  </div>
                </b-list-group-item>

                <b-list-group-item
                  v-if="filesInfo.documentation.informationParVersion"
                  button
                  class="d-flex align-items-center justify-content-between"
                  @click="
                    showFile(filesInfo.documentation.informationParVersion)
                  "
                >
                  {{ $t("importantInfo") }}
                  <div class="badge badge-red ml-2">
                    PDF
                  </div>
                </b-list-group-item>

                <b-list-group-item
                  v-if="filesInfo.documentation.evolutionDoc"
                  button
                  class="d-flex align-items-center justify-content-between"
                  @click="
                    showFile(filesInfo.documentation.evolutionDoc)
                  "
                >
                  {{ $t("evolution") }}
                  <div class="badge badge-red ml-2">
                    PDF
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>

            <div
              v-if="(user.admin ||
                (user.company.client && user.company.deployment == 'On_Premise'))"
              class="mb-3"
            >
              <div class="badge badge-dark">
                {{ $t("install") }} myCarto
              </div>
              <b-list-group>
                <b-list-group-item
                  v-if="filesInfo.installeur.zip"
                  button
                  class="d-flex align-items-center justify-content-between"
                  @click="showFile(filesInfo.installeur.zip.installer)"
                >
                  <div>{{ $t("install") }} myCarto</div>
                  <div class="badge badge-warning ml-2">
                    ZIP
                  </div>
                </b-list-group-item>
                <b-list-group-item
                  v-if="filesInfo.installeur.zip"
                  class="d-flex align-items-center justify-content-between"
                >
                  <span class="hash">
                    {{ filesInfo.installeur.zip.hash256 }}
                  </span>
                  <div class="badge badge-warning ml-2">
                    SHA256
                  </div>
                </b-list-group-item>
                <b-list-group-item
                  v-if="filesInfo.installeur?.zip?.installMyCartoNavigationServer"
                  button
                  class="d-flex align-items-center justify-content-between"
                  @click="showFile(filesInfo.installeur.zip.installMyCartoNavigationServer)"
                >
                  <div>{{ $t("installNav") }}</div>
                  <div class="badge badge-warning ml-2">
                    ZIP
                  </div>
                </b-list-group-item>
                <b-list-group-item
                  v-if="filesInfo.installeur.exe"
                  button
                  class="d-flex align-items-center justify-content-between"
                  @click="redirectToPage()"
                >
                  {{ $t("runtime") }}
                  <div class="badge badge-info ml-2">
                    EXE
                  </div>
                </b-list-group-item>
                <b-list-group-item
                  v-if="filesInfo.installeur.doc"
                  button
                  class="d-flex align-items-center justify-content-between"
                  @click="showFile(filesInfo.installeur.doc)"
                >
                  {{ $t("docInstall") }} myCarto
                  <div class="badge badge-danger ml-2">
                    PDF
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>

            <div
              class="mb-3"
            >
              <div class="badge badge-dark">
                {{ $t("mng") }} 
              </div>
              <b-list-group>
                <b-list-group-item
                  v-if="filesInfo.documentation.serverExpress"
                  button
                  class="d-flex align-items-center justify-content-between"
                  @click="showFile(filesInfo.documentation.serverExpress)"
                >
                  {{ $t("serverExpress") }}
                  <div class="badge badge-warning ml-2">
                    ZIP
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </div>
        <div class="col-8">
          <b-alert
            v-if="Patchnote.commentaire"
            variant="warning"
            show
          >
            {{ Patchnote.commentaire }}
          </b-alert>

          <div
            class="custom-nav-pills custom-nav-pills-s rounded mb-3 py-3 text-center"
          >
            <span
              class="text-center w-100"
              pills
              justified
            > myCarto</span>
          </div>

          <div>
            <b-card
              v-for="(item, index) in PatchnoteMode"
              :key="index"
              no-body
              class="mb-1"
            >
              <b-card-header
                header-tag="header"
                class="p-1"
                role="tab"
              >
                <b-button
                  v-b-toggle="'accordion-' + index"
                  class="d-flex align-items-center"
                  href="#"
                  show
                  variant="light"
                >
                  {{ item.version }}
                  <span class="ml-auto badge badge-dark">{{ item.date }}</span>
                </b-button>
              </b-card-header>
              <b-collapse
                :id="'accordion-' + index"
                :visible="index == 0 ? true : false"
                :accordion="'accordion-' + index"
                role="tabpanel"
              >
                <b-card-body>
                  <div
                    v-for="(type, typeIndex) in item.historique"
                    :key="typeIndex"
                  >
                    <div
                      v-for="(fix, index) in type"
                      :key="index"
                      class="ml-auto mb-1 p-2 rounded"
                      :class="
                        typeIndex == 'correction' ? 'bg-light ' : 'bg-light'
                      "
                    >
                      <div class="d-flex">
                        <span
                          class="mr-1 badge"
                          :class="
                            typeIndex == 'correction'
                              ? 'badge-dark'
                              : 'badge-success'
                          "
                        >{{
                          typeIndex == "correction" ? "Ticket" : "Evol"
                        }}</span>
                        <span
                          v-for="(ticket, ticketIndex) in fix.ticket"
                          :key="ticketIndex"
                          class="mr-1 badge badge-primary"
                        >{{ ticket }}</span>
                        <span class="ml-auto badge badge-secondary">{{
                          item.date
                        }}</span>
                      </div>
                      <template v-if="typeIndex == 'correction'">
                        {{ fix.text }}
                      </template>
                      <template v-else>
                        {{ fix }}
                      </template>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script  lang="ts">
import api from "@/api";
import Loader from "@/components/Loader.vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  data() {
    return {
      versionList: [],
      Patchnote: [],
      PatchnoteMode: [],
      filesInfo: null as null | string[],
      baseFileDownload: "https://soluqiq.fr/download/",
      loading: true,
    };
  },
  computed: {
    ...mapState("auth", ["loggedIn", "user"]),
  },
  created() {
    api.patchnotemycarto
      .getListVersion()
      .then((response) => response.data)
      .then((data) => {
        if (data.status) {
          this.versionList = data.results.reverse();
          this.getPatchnote();
          this.filesInfo = this.versionList[0]["files"];
        }
      });
  },
  methods: {
    getPatchnote() {
      api.patchnotemycarto
        .getPatchnote()
        .then((response) => response.data)
        .then((data) => {
          if (data.status) {
            this.Patchnote = data.results;
            this.PatchnoteMode = this.Patchnote["patchnote"];
            this.loading = false;
          }
        });
    },
    redirectToPage() {
      // Rediriger vers une page Internet dans un nouvel onglet
      window.open(
        "https://dotnet.microsoft.com/en-us/download/dotnet/thank-you/runtime-desktop-8.0.1-windows-x64-installer?cid=getdotnetcore",
        "_blank"
      );
    },
    showFile(fileName: string) {
      const url = this.baseFileDownload + "myCarto/" + fileName;
      window.open(url, "_blank");
    },
  },
  components: {
    Loader,
  },
});
</script>

<style scoped>
.hash {
  font-size: 12px;
  display: block;
  overflow-wrap: break-word;
  max-width: 13rem;
}
</style>
<i18n>
{
  "fr": {
    "preRequisite": "Pré-requis",
    "beforeConsult": "À consulter avant toute migration",
    "breakingChanges": "A lire avant toute mise à jour",
    "importantInfo": "Informations importantes par version",
    "evolution": "Evolutions",
    "installOf": "Installation complete",
    "install": "Installeur",
    "installNav": "Installeur du serveur de publication",
    "docInstall": "Documentation d'installation",
    "runtime": "Runtime Windows Desktop .NET",
    "lastPatchFor": "Patch ",
    "serverExpress": "Serveur Express",
    "mng": "MNG",
  },
  "en": {
    "preRequisite": "Pre-requisites",
    "beforeConsult": "To consult before any migration",
    "importantInfo": "Important information by version",
    "evolution": "Evolutions",
    "installOf": "Install of",
    "install": "installer",
    "installNav": "Publishing server installer",
    "breakingChanges": "To read before any update",
    "lastPatchFor": "Last patch for",
    "serverExpress": "Express Server",
    "mng":"MNG",
  }
}
</i18n>
