import { createStore } from 'vuex'
import auth from './modules/auth'
import locales from './modules/locales'

// Vue.use(Vuex)

const ICON_FAMILY_KEY = 'app.iconFamily'

export interface RootState {
  version: string;
  family: string;
}

export default createStore({
  state: {
    version: '1.0.0',
    family: localStorage.getItem(ICON_FAMILY_KEY) || 'fad'
  } as RootState,
  mutations: {
    setFamily: (state, payload: string) => {
      localStorage.setItem(ICON_FAMILY_KEY, payload)
      state.family = payload
    }
  },
  actions: {
  },
  modules: {
    auth,
    locales
  }
  
})
