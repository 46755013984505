import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_articles_list_item = _resolveComponent("articles-list-item")!
  const _component_b_list_group = _resolveComponent("b-list-group")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_list_group, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articlesFilter, (article, index) => {
          return (_openBlock(), _createBlock(_component_articles_list_item, {
            key: index,
            onDeleted: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getArticles())),
            aspect: _ctx.aspect,
            article: article,
            widget: _ctx.widget
          }, null, 8, ["aspect", "article", "widget"]))
        }), 128))
      ], undefined, true),
      _: 1
    })
  ]))
}