export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditer"])},
        "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat"])}
      },
      "en": {
        "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q&A"])},
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
        "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No result"])}
      }
    }
  })
}
