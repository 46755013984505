import instance from '@/api/instance'

interface User {
  login: string;
  firstname: string;
  lastname: string;
  newsletter: boolean;
}

export default {
  updateUser: (user: User) => {
    return instance.post('/user/update', user)
  },
  updateSession: () => {
    return instance.get('/user/update')
  },
  count: () => {
    return instance.get('/admin/users/count')
  },
  countUserInscriNewLetter: () => {
    return instance.get('/admin/users/newsletter/count')
  }
}
