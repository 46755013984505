<template>
  <div>
    <b-list-group>
      <articles-list-item
        v-for="(article, index) in articlesFilter" :key="index"
        @deleted="getArticles()"
        :aspect="aspect"
        :article="article"
        :widget="widget"
      />
    </b-list-group>
  </div>
</template>

<script  lang="ts">
import api from '@/api'
import ArticlesListItem from './ArticlesListItem.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    ArticlesListItem
  },
  props: {
    aspect: {
      type: String,
      required: false,
      default: '16:12'
    },
    filter: {
      type: String,
      required: false,
      default: null
    },
    widget: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      articles : []
    }
  },
  created () {
    this.getArticles()
  },
  computed: {
    articlesFilter() {
      return this.filter ? this.articles.filter(a => a.title.toLowerCase().includes(this.filter) || a.content.toLowerCase().includes(this.filter)) : this.articles
    }
  },
  methods: {
    getArticles() {
      api.blog.getArticles()
      .then(response => response.data)
      .then(data => {
        this.articles = data.results.reverse()
      })
    },

  }
})
</script>
