export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "articleTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancée du développement"])},
        "addArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher un article"])}
      },
      "en": {
        "articleTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development progress"])},
        "addArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search article"])}
      }
    }
  })
}
