
import Default from '@/views/Default.vue'
import Explorer from '@/components/pages/explorer/Explorer.vue'

export default  [
  {
    path: '/explorer',
    component: Default,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'Explorer',
        component: Explorer
      }
    ]
  }
] 
