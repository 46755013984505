export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
        "registredNewsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrits à la newsletter"])},
        "activeUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs actifs"])},
        "greeting": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bonjour ", _interpolate(_named("name"))])},
        "newsletterSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous à la newsletter"])},
        "subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes inscrit à la newsletter"])},
        "actualitesArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualités / Articles"])},
        "card": {
          "demonstration": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démonstrations"])}
          },
          "lastPatch": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier patch : SOLU-QIQ"])}
          },
          "lastPatchWeb": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier patch : Web Contributeur"])}
          },
          "admin": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
            "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
            "addCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises"])}
          }
        }
      },
      "en": {
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
        "registredNewsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered to the newsletter"])},
        "activeUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active users"])},
        "greeting": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hello ", _interpolate(_named("name"))])},
        "newsletterSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to the newsletter"])},
        "subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are subscribed to the newswletter"])},
        "actualitesArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualities / Articles"])},
        "card": {
          "demonstration": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demonstrations"])}
          },
          "lastPatch": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last patch: SOLU-QIQ"])}
          },
          "lastPatchWeb": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last patch: Web Contributeur"])}
          },
          "admin": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
            "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
            "addCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])}
          }
        }
      }
    }
  })
}
