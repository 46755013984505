import { Module } from 'vuex'
import { RootState } from '../index'

export interface LocalesState {
  locale: string;
}

const APP_LOCALES = 'app.locale'

const locales: Module<LocalesState, RootState> = {
  namespaced: true,

  state: {
    locale: localStorage.getItem(APP_LOCALES) || 'fr'
  },

  mutations: {
    setLocale: (state, payload) => {
      localStorage.setItem(APP_LOCALES, payload)
      state.locale = payload
    }
  }
}

export default locales
