<template>
  <div v-if="article">
    <b-card
      tag="article"
      class="mb-3"
      body-class="m-0 p-0 image-control"
    >
      <div class="w-100" :style="{overflow: 'hidden',}">
        <div class="w-100 position-absolute" style="z-index:999;">
          <b-aspect :aspect="aspect" class="d-flex">
            <b-button
              @click="$router.go(-1)"
              type="submit"
              variant="dark"
              class="m-1 position-absolute"
            >
              <font-awesome-icon :icon="[$store.state.family, 'arrow-left']" />
            </b-button>
            <div class="d-flex flex-column align-items-center justify-content-center h-100 w-100">
              <h3 class="text-white text-shadow">{{article.title}}</h3>
              <div class="badge badge-dark">{{article.date}}</div>
            </div>
          </b-aspect>
        </div>
        <div :style="style" class="rounded-top">
          <b-aspect :aspect="aspect" class="d-flex">
          </b-aspect>
        </div>
      </div>
      <b-card-text class="p-5" v-html="article.content">
      </b-card-text>
    </b-card>
  </div>
</template>

<script  lang="ts">
import api from '@/api'
import { getAbsoluteURL } from '@/tools'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      article: null
    }
  },
  created() {
    api.blog.getArticleById(this.$route.params.id)
      .then(response => response.data)
      .then(data => {
        this.article = data.results
      })
  },
  computed: {
    aspect() {
      return '16:4'
    },
    thumbnail() {
      return getAbsoluteURL(this.article.thumbnail)
    },
    style(){
      return {
        backgroundImage: 'url(' + this.thumbnail + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'gray',
        filter: 'blur(30px)',
      }
    }
  }

})
</script>

<style>
  .text-shadow{
    text-shadow: 0px 0px 8px black !important;
  }
  .font-size-4{
    font-size: 4rem;
  }
  .image-control img{
    max-width: 100% !important;
  }
</style>
