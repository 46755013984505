import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "d-flex align-items-center align-self-center" }
const _hoisted_4 = { role: "tablist" }
const _hoisted_5 = { class: "badge badge-primary ml-auto" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_card_header = _resolveComponent("b-card-header")!
  const _component_b_card_text = _resolveComponent("b-card-text")!
  const _component_b_card_body = _resolveComponent("b-card-body")!
  const _component_b_collapse = _resolveComponent("b-collapse")!
  const _component_b_card = _resolveComponent("b-card")!
  const _component_b_alert = _resolveComponent("b-alert")!
  const _directive_b_toggle = _resolveDirective("b-toggle")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('faq')), 1),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.isAdmin)
            ? (_openBlock(), _createBlock(_component_b_button, {
                key: 0,
                variant: "primary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$bvModal.show('bv-modal-ajout')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: [_ctx.$store.family, 'plus']
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('add')), 1)
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_b_form_group, {
            class: _normalizeClass(["ml-auto", _ctx.isAdmin ? 'w-50' : 'w-100'])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_form_input, {
                class: "flex-fill",
                modelValue: _ctx.search,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
                placeholder: "Rechercher une question..."
              }, null, 8, ["modelValue"])
            ], undefined, true),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faqList, (item, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createVNode(_component_b_card, {
                "no-body": "",
                class: "mb-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_card_header, {
                    "header-tag": "header",
                    class: "p-1",
                    role: "tab"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_component_b_button, {
                        class: "d-flex align-items-center text-left",
                        block: "",
                        href: "#",
                        variant: "transparent"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h5", null, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: [_ctx.$store.state.family, 'chevron-down'],
                              "fixed-width": ""
                            }, null, 8, ["icon"]),
                            _createTextVNode(" " + _toDisplayString(item.question), 1)
                          ]),
                          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$filters.calendar(item.creation_date)), 1),
                          (_ctx.isAdmin)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "btn btn-sm btn-warning ml-2",
                                onClick: ($event: any) => (_ctx.$emit('currentModification', {
                      id: _ctx.faqList[index].id,
                      question: _ctx.faqList[index].question,
                      reponse: _ctx.faqList[index].reponse
                    }))
                              }, _toDisplayString(_ctx.$t('edit')), 9, _hoisted_6))
                            : _createCommentVNode("", true),
                          (_ctx.isAdmin)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: "btn btn-sm btn-danger ml-2",
                                onClick: ($event: any) => (_ctx.$emit('currentSuppression', {id: _ctx.faqList[index].id}))
                              }, [
                                _createVNode(_component_font_awesome_icon, {
                                  icon: [_ctx.$store.state.family, 'trash-alt']
                                }, null, 8, ["icon"])
                              ], 8, _hoisted_7))
                            : _createCommentVNode("", true)
                        ], undefined, true),
                        _: 2
                      }, 1024)), [
                        [_directive_b_toggle, 'collapse-' + index]
                      ])
                    ], undefined, true),
                    _: 2
                  }, 1024),
                  _createVNode(_component_b_collapse, {
                    class: "text-dark",
                    id: 'collapse-' + index,
                    accordion: "collapse",
                    role: "tabpanel"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_card_body, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_card_text, {
                            innerHTML: _ctx.mToHTML(item.reponse)
                          }, null, 8, ["innerHTML"])
                        ], undefined, true),
                        _: 2
                      }, 1024)
                    ], undefined, true),
                    _: 2
                  }, 1032, ["id"])
                ], undefined, true),
                _: 2
              }, 1024)
            ]))
          }), 128)),
          (_ctx.faqList.length == 0)
            ? (_openBlock(), _createBlock(_component_b_alert, {
                key: 0,
                variant: "warning",
                show: "",
                dismissible: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('noResult')), 1)
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}