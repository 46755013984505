import { createApp } from "@vue/compat";
import store from './store'
import App from './App.vue'
import router from './router'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Import des librairies
import { BootstrapVue } from 'bootstrap-vue'
import i18n from './i18n'
import VueMoment from 'vue-moment'

// Import des assets
import '@/scss/custom.scss'
import './filters'
import './icons'

import moment from 'moment'
require('moment/locale/fr')
const app = createApp(App);
app.use(BootstrapVue)
app.use(VueMoment, {
  moment
})

// app.use(VueI18n)
// i18n.silentFallbackWarn = true
app.use(router);
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(store);
app.use(i18n);

app.config.globalProperties.$filters = {
  calendar(value) {
    if ((typeof value) === 'number') {
      return moment.unix(value).format('DD/MM/YYYY')
    } else {
      return moment(value, 'YYYY-MM-DD HH:mm:ss').calendar()
      
    }
  },
  dateSince(value) {
    return moment(value, 'YYYY-MM-DD HH:mm:ss').fromNow()
  },
  
}


app.mount('#app');
