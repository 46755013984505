import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "w-100",
  style: {overflow: 'hidden',}
}
const _hoisted_3 = {
  class: "w-100 position-absolute",
  style: {"z-index":"999"}
}
const _hoisted_4 = { class: "d-flex flex-column align-items-center justify-content-center h-100 w-100" }
const _hoisted_5 = { class: "text-white text-shadow" }
const _hoisted_6 = { class: "badge badge-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_aspect = _resolveComponent("b-aspect")!
  const _component_b_card_text = _resolveComponent("b-card-text")!
  const _component_b_card = _resolveComponent("b-card")!

  return (_ctx.article)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_b_card, {
          tag: "article",
          class: "mb-3",
          "body-class": "m-0 p-0 image-control"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_b_aspect, {
                  aspect: _ctx.aspect,
                  class: "d-flex"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_button, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
                      type: "submit",
                      variant: "dark",
                      class: "m-1 position-absolute"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, {
                          icon: [_ctx.$store.state.family, 'arrow-left']
                        }, null, 8, ["icon"])
                      ], undefined, true),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.article.title), 1),
                      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.article.date), 1)
                    ])
                  ], undefined, true),
                  _: 1
                }, 8, ["aspect"])
              ]),
              _createElementVNode("div", {
                style: _normalizeStyle(_ctx.style),
                class: "rounded-top"
              }, [
                _createVNode(_component_b_aspect, {
                  aspect: _ctx.aspect,
                  class: "d-flex"
                }, null, 8, ["aspect"])
              ], 4)
            ]),
            _createVNode(_component_b_card_text, {
              class: "p-5",
              innerHTML: _ctx.article.content
            }, null, 8, ["innerHTML"])
          ], undefined, true),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}