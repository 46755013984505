<template>
  <b-overlay :show="load" rounded="sm">
    <b-card
      :title="$t('blogTitle')"
      :sub-title="$t('subTitre')"
      tag="article"
      class="mb-2"
    >
      <b-card-body class="p-0">
        <div v-if="articles.length > 0">
          <b-list-group class="mb-0 mt-4">
            <articles-list-item
              v-for="(article, index) in articles" :key="index"
              @deleted="getArticles()"
              :aspect="aspect"
              :article="article"
              :widget="widget"
            />
          </b-list-group>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script  lang="ts">
import api from '@/api'
import ArticlesListItem from '@/components/pages/articles/ArticleListWidget.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    ArticlesListItem
  },
  props: {
    aspect: {
      type: String,
      required: false,
      default: '16:12'
    },
    widget: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      load: false,
      articles : []
    }
  },
  created () {
    this.getArticles()
  },
  methods: {
    getArticles() {
      this.load = true
      api.blog.getArticles()
      .then(response => response.data)
      .then(data => {
        this.articles = data.results.reverse().filter((item, index) => index <= 3)
        this.load = false
      })
    },

  }
})
</script>

<i18n>
  {
    "fr": {
      "blogTitle": "Devblog",
      "subTitre": "Retrouvez ici toutes les informations sur le redéveloppement de SOLU-QIQ."
    },
    "en": {
      "blogTitle": "Devblog",
      "subTitre": ""
    }
  }
</i18n>
