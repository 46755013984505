export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous supprimer cette article ?"])},
        "toast": {
          "saveSuccess": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistré"])},
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les modifications ont bien été enregistrées."])}
          }
        },
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditer"])}
      },
      "en": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this article ?"])},
        "toast": {
          "saveSuccess": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edits have been saved."])}
          }
        },
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])}
      }
    }
  })
}
