import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "font-weight-bold d-flex justify-content-between mb-5" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "text-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_articles_list = _resolveComponent("articles-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h3", _hoisted_1, [
      _createTextVNode(_toDisplayString(_ctx.$t('articleTitle')) + " ", 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_b_form_input, {
          class: "mr-2",
          modelValue: _ctx.filter,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
          placeholder: _ctx.$t('search')
        }, null, 8, ["modelValue", "placeholder"]),
        (_ctx.user.admin)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_b_button, {
                variant: "primary",
                to: { name: 'NewArticle' }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('addArticle')), 1)
                ], undefined, true),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_articles_list, { filter: _ctx.filter }, null, 8, ["filter"])
  ]))
}