import { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router';
import home from './modules/home'
import admin from './modules/admin'
import explorer from './modules/explorer'
import articles from './modules/articles'
import store, { RootState } from '@/store'


const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "login" */ '../views/Default.vue'),
    meta: {
      auth: true
    },
    children: [
      ...home
    ]
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Default.vue'),
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '@/components/pages/Login.vue')
      }
    ]
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "login" */ '@/components/pages/ForgotPassword.vue')
  },
  {
    path: '/reset-password',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Empty.vue'),
    children: [
      {
        path: ':token',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "login" */ '@/components/pages/ResetPassword.vue'),
      }
    ]
  },
  ...admin,
  ...explorer,
  ...articles
] as RouteRecordRaw[]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/**
 * Avant chaque appel du routeur vérifie que l'utilisateur est connecté
 * si la route require une authentification, et que l'utilisateur est un
 * administrateur s'il en est un
 */
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    store.dispatch('auth/checkSession')
      .finally(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any        
        if ((store.state as any|RootState).auth.loggedIn) {
          if (to.matched.some(record => record.meta.admin)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((store.state as any|RootState).auth.user.admin) {
              next()
            } else {
              next({
                name: 'Home'
              })
            }
          } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (to.matched.some(record => record.meta.product == (store.state as any).auth.user.company.product) || (store.state as any).auth.user.admin) {
              next()
            }else if(!to.matched.some(record => record.meta.product)){
              next()
            }else{
              next({
                name: 'Home'
              })
            }
          }
        } else {
          next({
            name: 'Login'
          })
        }
      })
  } else {
    next()
  }
})

export default router
