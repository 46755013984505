<template>
  <div class="py-1">
    <div class="text-muted d-flex justify-content-between align-items-end">
      <small class="font-weight-bold text-truncate pr-2" :style="{ minWidth: 0 }">
        {{ file.name }}
      </small>
      <span v-if="done" class="text-success">
        <font-awesome-icon :icon="[$store.state.family, 'check']" />
      </span>
    </div>
    <b-progress :value="progress" height=".4rem" />
  </div>
</template>

<script  lang="ts">
import api from '@/api'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    file: {
      type: File,
      required: true
    },
    path: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      progress: 0,
      done: false,
      error: false
    }
  },
  mounted () {
    const formData = new FormData()
    formData.append('file', this.file)
    api.explorer.uploadFile({
      formData,
      path: (this.path as string),
      onProgress: ({ progress }: { progress: number }) => {
        this.progress = progress
      },
      onDone: () => {
        this.done = true
        this.$emit('done')
      },
      onError: () => {
        this.done = true
        this.error = true
      }
    })
  }
})
</script>
