export default {
  "app": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace client"])}
  },
  "form": {
    "login": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])}
    }
  }
}