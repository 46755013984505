<template>
  <div>
      <b-list-group-item
        class="p-0"
        :class="!widget ? 'shadow mb-3 border-0' : 'border mb-2'"
      >
        <div class="row no-gutters">
          <div v-if="imageExist" class="col-sm-12 col-md-6 col-lg-2" >
            <div
              v-if="article.thumbnail != 'null'"
              class="w-100"
            >
              <div :style="style" class="rounded-left">
                <b-aspect :aspect="aspect" />
              </div>
            </div>
          </div>
          <div class="col d-flex flex-column align-items-center">
            <div class=" p-3 position-relative">
              <b-link href="#" :to="{ name: 'Article', params: { id: article.id } }" class="text-dark stretched-link">
                <h4>{{title}}</h4>
              </b-link>
              <p class="text-muted" v-html="extractContent(article.content)"></p>
            </div>
          </div>
          <div v-if="!widget" class="col-lg-auto col-md-12 p-3">
            <div>
              <div class="badge badge-light ml-auto">
                <font-awesome-icon class="mr-1" :icon="[$store.state.family, 'calendar-alt']" />
                {{ $filters.calendar(date) }}
              </div>
              <div class="badge badge-light">
                <font-awesome-icon class="mr-1" :icon="[$store.state.family, 'calendar-alt']" />
                {{ heure }}
              </div>
            </div>
            <div v-if="user.admin" class="d-flex mt-2 ml-auto">
              <b-button
                :to="{ name: 'ArticleEdit', params: {id: article.id} }"
                type="submit"
                variant="warning"
                class="m-1 w-100"
              >
                {{ $t('edit') }}
              </b-button>
              <b-button
                @click="deleted(article.id)"
                type="submit"
                variant="danger"
                class="m-1"
              >
                <font-awesome-icon :icon="[$store.state.family, 'trash-alt']" />
              </b-button>
            </div>
          </div>
        </div>
      </b-list-group-item>
  </div>
</template>

<script  lang="ts">
import { mapState } from 'vuex'
import api from '@/api'
import { getAbsoluteURL, truncateString } from '@/tools'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    article: {
      type: Object,
      required: true,
    },
    aspect: {
      type: String,
      required: false,
      default: '16:12'
    },
    widget: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState('auth', { storedUser: 'user' }),
    ...mapState('auth', ['loggedIn', 'user']),
    title(){
      const title = truncateString(this.article.title, (!this.widget ? 50 : 40))
      return title +  (title.length == (!this.widget ? 50 : 40) ? '...' : '')
    },
    date() {
      return new Date(this.article.date)
    },
    heure() {
      return this.date.getHours() + ':' + this.date.getMinutes() + ':' + this.date.getSeconds()
    },
    imageExist() {
      return this.article.thumbnail == "null" ? false : true
    },
    thumbnail() {
      return getAbsoluteURL(this.article.thumbnail)
    },
    style(){
      return {
        backgroundImage: 'url(' + this.thumbnail + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#e9e9e9'
      }
    }
  },
  methods: {
    extractContent (s) {
      const regex = /H[1-6]/gi
      const span = document.createElement('span');
      span.innerHTML = s;
      let content = ''
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      span.childNodes.forEach((n: any) => {
        if (!n.nodeName.match(regex)) {
          content += n.textContent
        }
      })
      return truncateString(content, this.article.thumbnail == "null" ? 250 : 175 ) + "...";
    },
    deleted (id: number) {
      if (confirm(this.$t('confirm'))){
        api.blog.delete(id)
          .finally(() => {
              this.$emit('deleted')
              this.$root.$bvToast.toast(this.$t('toast.saveSuccess.body'), {
                variant: 'success',
                title: this.$t('toast.saveSuccess.title')
              })
            })
      }
    }
  }
})
</script>

<i18n>
  {
    "fr": {
      "confirm": "Voulez-vous supprimer cette article ?",
      "toast": {
        "saveSuccess": {
          "title": "Enregistré",
          "body": "Les modifications ont bien été enregistrées."
        }
      },
      "edit": "Éditer"
    },
    "en": {
      "confirm": "Are you sure you want to delete this article ?",
      "toast": {
        "saveSuccess": {
          "title": "Saved",
          "body": "Edits have been saved."
        }
      },
      "edit": "Edit"
    }
  }
</i18n>
