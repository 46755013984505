<template>
  <div>
    <div class="row">
      <!-- COL Liste de categories
      <div class="col-4">
        <b-list-group>
          <h6 class="rounded text-white p-2 bg-primary">Categories</h6>
          <b-list-group-item v-for="(cat, index) in categoryList" :key="index"
          button>{{cat}}</b-list-group-item>
        </b-list-group>
      </div>-->

      <!-- COL Liste de FAQ -->
      <div class="col-12">
        <h2>{{ $t('faq') }}</h2>
        <div class="d-flex align-items-center align-self-center">
          <b-button v-if="isAdmin" variant="primary" @click="$bvModal.show('bv-modal-ajout')">
            <font-awesome-icon :icon="[$store.family, 'plus']" />
            {{ $t('add') }}
          </b-button>
          <b-form-group class="ml-auto" :class="isAdmin ? 'w-50' : 'w-100'">
            <b-form-input class="flex-fill" v-model="search" placeholder="Rechercher une question..."></b-form-input>
          </b-form-group>
        </div>
         <div role="tablist">
          <div v-for="(item, index) in faqList" :key="index">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button class="d-flex align-items-center text-left" block href="#" v-b-toggle="'collapse-' + index" variant="transparent">
                  <h5>
                    <font-awesome-icon :icon="[$store.state.family, 'chevron-down']" fixed-width />
                    {{ item.question }}
                  </h5>
                  <div class="badge badge-primary ml-auto">
                    {{ $filters.calendar(item.creation_date) }}
                  </div>
                  <div
                    class="btn btn-sm btn-warning ml-2"
                    v-if="isAdmin"
                    @click="$emit('currentModification', {
                      id: faqList[index].id,
                      question: faqList[index].question,
                      reponse: faqList[index].reponse
                    })"
                    >
                    {{ $t('edit') }}
                  </div>
                  <div
                    class="btn btn-sm btn-danger ml-2"
                    v-if="isAdmin"
                    @click="$emit('currentSuppression', {id: faqList[index].id})"
                    >
                    <font-awesome-icon :icon="[$store.state.family, 'trash-alt']" />
                  </div>
                </b-button>
              </b-card-header>
              <b-collapse class="text-dark" :id="'collapse-' + index" accordion="collapse" role="tabpanel">
                <b-card-body>
                  <b-card-text v-html="mToHTML(item.reponse)">
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <b-alert v-if="faqList.length == 0" variant="warning" show dismissible>{{ $t('noResult') }}</b-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script  lang="ts">
import DOMPurify from 'dompurify'
import Marked from 'marked'
import api from '@/api'
import { mapState } from 'vuex'
import { Router } from 'vue-router'

import { defineComponent } from 'vue'

export default defineComponent({
  props:{
    reload:{
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(){
    return{
      faqList:[],
      categoryList: ['toto','tata','SO'],
      search:'',
      cr: '' as string | undefined,
    }
  },
  computed: {
    ...mapState('auth', { storedUser: 'user' }),
    getCurrentRouter(): Router {
      return this.$router.currentRoute
    },
    isAdmin(): boolean {
      if (this.getCurrentRouter.name == 'Admin.faq') {
        return this.storedUser.admin
      }else{
        return false
      }
    }
  },
  methods:{
    refresh(){
      api.faq.getFaq()
      .then(response => response.data)
      .then(data => {
        if (data.status) {
          this.faqList = data.results.filter((item: {question: string}) => {
            const q = item.question.toString()
            return (q as string).includes(this.search)
          }).reverse()
        }
      })
    },
    mToHTML(m: string): string{
      return DOMPurify.sanitize(Marked(m.toString()))
    }
  },
  created(){
    this.refresh()
    this.cr = this.getCurrentRouter.name
  },
  watch:{
    reload(val: boolean){
      this.refresh()
      this.$emit('reloadChange',!val)
    },
    search(){
      this.refresh()
    }
  }

})
</script>

<i18n>
{
  "fr": {
    "faq": "FAQ",
    "add": "Ajouter",
    "edit": "Éditer",
    "noResult": "Aucun résultat"
  },
  "en": {
    "faq": "Q&A",
    "add": "Add",
    "edit": "Edit",
    "noResult": "No result"
  }
}
</i18n>
