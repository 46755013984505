export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bienvenue ", _interpolate(_named("name"))])},
        "form": {
          "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
          "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
          "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
          "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
          "badPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])},
          "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le mot de passe"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])}
        },
        "toast": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistré"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les changements ont été enregistrés avec succès."])}
        }
      },
      "en": {
        "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welcome ", _interpolate(_named("name"))])},
        "form": {
          "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
          "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
          "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
          "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
          "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
          "badPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords are not equal"])},
          "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update password"])},
          "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
        },
        "toast": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edits have been saved."])}
        }
      }
    }
  })
}
