export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "list": {
          "usefull": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens utiles :"])},
            "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])}
          },
          "socialNetworks": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseaux sociaux"])}
          }
        },
        "cookieUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En utilisant ce site web, vous consentez à l'utilisation des cookies obligatoires au bon fonctionnement du site."])}
      },
      "en": {
        "list": {
          "usefull": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usefull links:"])},
            "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
          },
          "socialNetworks": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social networks"])}
          }
        },
        "cookieUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By using this site, you agree the use of required cookies."])}
      }
    }
  })
}
