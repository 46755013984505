export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "preRequisite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pré-requis"])},
        "beforeConsult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À consulter avant toute migration"])},
        "importantInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations importantes par version"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
        "installOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation complete"])},
        "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installer"])},
        "lastPatchFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patch "])},
        "updateSQServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installer et mettre à jour les services SOLU-QIQ"])},
        "installGA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation du service Graphes Auto"])},
        "updateWAPI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour SOLU-QIQ Web API"])},
        "installSQAuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation du service SQAuto"])}
      },
      "en": {
        "preRequisite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-requisites"])},
        "beforeConsult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To consult before any migration"])},
        "importantInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important information by version"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
        "installOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install of"])},
        "install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation"])},
        "lastPatchFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last patch for"])},
        "updateSQServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install and update SOLU-QIQ services"])},
        "installGA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install of Graphes Auto service"])},
        "updateWAPI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update SOLU-QIQ Web API"])},
        "installSQAuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install of SQAuto service"])}
      }
    }
  })
}
