import instance from '@/api/instance'

export default {
  getPatchnote: () => {
    return instance.get('/PatchnoteMyCarto')
  },
  getListVersion: () => {
    return instance.get('/Patchnote/mycarto/list')
  }
}
