export default {
  "app": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer zone"])}
  },
  "form": {
    "login": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])}
    }
  }
}