import instance from '@/api/instance'
import { User, Newsletter, Company } from '@/types'

export default {
  getAllUsers: () => {
    return instance.get('/admin/users')
  },
  getUser: (id: number) => {
    return instance.get(`/admin/user/${id}`)
  },
  addUser: (user: User) => {
    return instance.post('/admin/user', user)
  },
  getAllNewsletters: () => {
    return instance.get('/admin/newsletters')
  },
  getNewsletter: (id: number) => {
    return instance.get(`/admin/newsletter/${id}`)
  },
  sendNewsletter: (newsletter: Newsletter) => {
    return instance.post('/admin/newsletter', newsletter)
  },
  getAllCompanies: () => {
    return instance.get('/admin/companies')
  },
  deleteUser: (id: number) => {
    return instance.delete('/admin/user', {
      params: {
        id: id
      }
    })
  },
  deleteCompany: (id: number) => {
    return instance.delete('/admin/company', {
      params: {
        id: id
      }
    })
  },
  updateUser: (user: User) => {
    return instance.put('/admin/user', {
      user
    })
  },
  usersWhoReceivedNewsletter: (id: number) => {
    return instance.get(`/admin/newsletter/${id}/users`)
  },
  companies: {
    update: (company: Company) => {
      return instance.put(`/admin/companies/${company.id}`, {company})
    },
    add: (company: Company) => {
      return instance.post('/admin/companies', {company})
    },
    get: (id: number) => {
      return instance.get(`/admin/companies/${id}`)
    }
  },
  newsletters: {
    getAll: () => {
      return instance.get('/admin/newsletters')
    },
    get: (id: number) => {
      return instance.get(`/admin/newsletter/${id}`)
    },
    add: (newsletter: Newsletter) => {
      return instance.post('/admin/newsletter', newsletter)
    }
  },
  updatePassword: (form) => {
    return instance.post('/admin/user/password', form)
  }
}
