import instance from '@/api/instance'

export default {

  // Récupère un article a partir d'un id
  getArticleById: (id: number) => {
    return instance.get(`/article/${id}`,)
  },

  // Récupère la liste complete d'articles
  getArticles: () => {
    return instance.get('/article/all')
  },

  addArticle: (article: FormData) => {
    return instance.post('/admin/article', article, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  update: (id: number, article: FormData) => {
    return instance.post(`/admin/article/${id}`, article, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  delete: (id: number) => {
    return instance.delete(`/admin/article/${id}`)
  }
}
