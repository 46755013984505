import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_articles_list_item = _resolveComponent("articles-list-item")!
  const _component_b_list_group = _resolveComponent("b-list-group")!
  const _component_b_card_body = _resolveComponent("b-card-body")!
  const _component_b_card = _resolveComponent("b-card")!
  const _component_b_overlay = _resolveComponent("b-overlay")!

  return (_openBlock(), _createBlock(_component_b_overlay, {
    show: _ctx.load,
    rounded: "sm"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_card, {
        title: _ctx.$t('blogTitle'),
        "sub-title": _ctx.$t('subTitre'),
        tag: "article",
        class: "mb-2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_card_body, { class: "p-0" }, {
            default: _withCtx(() => [
              (_ctx.articles.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_b_list_group, { class: "mb-0 mt-4" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articles, (article, index) => {
                          return (_openBlock(), _createBlock(_component_articles_list_item, {
                            key: index,
                            onDeleted: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getArticles())),
                            aspect: _ctx.aspect,
                            article: article,
                            widget: _ctx.widget
                          }, null, 8, ["aspect", "article", "widget"]))
                        }), 128))
                      ], undefined, true),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ], undefined, true),
            _: 1
          })
        ], undefined, true),
        _: 1
      }, 8, ["title", "sub-title"])
    ], undefined, true),
    _: 1
  }, 8, ["show"]))
}