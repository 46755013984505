<template>
  <div v-if="this.user.admin">
    <form @submit.prevent="onSubmit">
      <h3 class="font-weight-bold d-flex justify-content-between">
        <template v-if="newArticle">
          {{ $t('titleNewArticle') }}
        </template>
        <template v-else>
          {{ $t('titleEditArticle') }}
        </template>
        <div>
          <b-button class="mr-1" @click="$router.go(-1)">
            <font-awesome-icon :icon="[$store.state.family, 'arrow-left']" />
            {{ $t('cancelButton') }}
          </b-button>
          <b-button
            type="submit"
            variant="primary"
            :disabled="!isValid"
          >
            <font-awesome-icon :icon="[$store.state.family, 'paper-plane']" />
            {{ $t('publishButton') }}
          </b-button>
        </div>
      </h3>
      <div class="row">
        <div class="col">
          <b-form-group>
            <b-form-input
              v-model="title"
              placeholder="Titre"
              size="lg"
            />
          </b-form-group>
          <b-form-group>
            <vue-editor
              v-model="content"
              class="bg-white"
            />
          </b-form-group>
          <b-form-group class="text-right">

          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-file v-model="thumbnail" id="file" class="d-none" accept="image/*" />
          <label class="d-flex flex-column cursor-pointer" for="file">
            <template v-if="thumbnail === null">
              <div class="border bg-200 w-100 rounded">
                <b-aspect
                  aspect="16:9"
                  class="d-flex text-center align-items-center"
                >
                  {{ $t('noImageText') }}
                </b-aspect>
              </div>
            </template>
            <template v-else>
              <div :style="style" class="rounded-left">
                <b-aspect aspect="16:12" />
              </div>
              <a href="#" @click.prevent="thumbnail = null" class="text-muted text-center w-100">
                {{ $t('deleteImage') }}
              </a>
            </template>
          </label>
        </div>
      </div>
    </form>
  </div>
</template>

<script  lang="ts">
import api from '@/api'
import { mapState } from 'vuex'
import { VueEditor } from 'vue2-editor'
import { getAbsoluteURL } from '@/tools'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    VueEditor
  },
  props: {
    newArticle: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data () {
    return {
      title: '',
      content: '',
      thumbnail: null,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    thumbnailURL () {
      if (this.thumbnail === null) {
        return null
      }

      if (typeof this.thumbnail === 'string') {
        return getAbsoluteURL(this.thumbnail)
      }

      const urlCreator = window.URL || window.webkitURL
      return urlCreator.createObjectURL(this.thumbnail)
    },
    isValid () {
      return this.title.length > 0 && this.content.length > 0
    },
    style(){
      return {
        backgroundImage: 'url(' + this.thumbnailURL + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#e9e9e9'
      }
    }
  },
  methods: {
    onSubmit () {
      const formData = new FormData()
      if (typeof(this.thumbnail) === 'object' || this.thumbnail === null) {
        formData.append('thumbnail', this.thumbnail)
      }
      formData.append('title', this.title)
      formData.append('content', this.content)

      if (this.newArticle) {
        api.blog.addArticle(formData)
          .then(() => {
            this.$root.$bvToast.toast(this.$t('addToastContent'), {
              title: 'Information',
              variant: 'success'
            })
            this.$router.push({ name: 'Articles' })
          })
          .catch(() => {
            this.$root.$bvToast.toast(this.$t('errorToastContent'), {
              title: 'Information',
              variant: 'danger'
            })
          })
      } else {
        api.blog.update(this.id, formData)
          .then(() => {
            this.$root.$bvToast.toast(this.$t('updateToastContent'), {
              title: 'Information',
              variant: 'success'
            })
            this.$router.push({ name: 'Articles' })
          })
          .catch(() => {
            this.$root.$bvToast.toast(this.$t('errorToastContent'), {
              title: 'Information',
              variant: 'danger'
            })
          })
      }
    }
  },
  created () {
    if (!this.user.admin) {
      this.$router.push({ name: 'Home' })
    }
    if (!this.newArticle) {
      api.blog.getArticleById(this.id)
        .then(response => response.data)
        .then(data => {
          this.title = data.results.title
          this.content = data.results.content
          this.thumbnail = data.results.thumbnail === 'null' ? null : data.results.thumbnail
        })
    }
  }
})
</script>
<style>
  .ql-editor{
    overflow-y: auto !important;
    max-height: 69vh !important;
  }
  .ql-editor img {
    max-width: 100% !important;
    height: auto !important;
  }
</style>
<i18n>
{
  "fr": {
    "titleNewArticle": "Nouvel article",
    "titleEditArticle": "Édition de l'article",
    "publishButton": "Publier",
    "cancelButton": "Annuler",
    "addToastContent": "L'article a bien été publié.",
    "updateToastContent": "L'article a bien été mis à jour.",
    "errorToastContent": "Une erreur est survenue.",
    "noImageText": "Aucune image sélectionnée",
    "deleteImage": "Supprimer l'illustration"
  },
  "en": {
    "titleNewArticle": "New article",
    "titleEditArticle": "Article edition",
    "publishButton": "Publish",
    "cancelButton": "Cancel",
    "addToastContent": "The article has been published.",
    "updateToastContent": "The article has been updated.",
    "errorToastContent": "An error occured.",
    "noImageText": "No image selected",
    "deleteImage": "Delete thumbnail"
  }
}
</i18n>
