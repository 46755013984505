<template>
  <div>

    <h1 class="text-center bg-300 mb-5 p-1 rounded">{{ $t('welcome', {name: `${storedUser.firstname} ${storedUser.lastname}`}) }}</h1>
    <b-row class="justify-content-center">
      <b-col md="5" lg="4" class="mb-3">
        <div class="text-center text-primary display-2 mb-3">
          <font-awesome-icon :icon="[$store.state.family, 'user-circle']" />
        </div>
        <b-form @submit.prevent="onSubmit">
          <b-form-row>
            <b-col sm="6">
              <b-form-group :label="$t('form.firstname')">
                <b-form-input v-model="user.firstname" :placeholder="$t('form.firstname')" />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group :label="$t('form.lastname')">
                <b-form-input v-model="user.lastname" :placeholder="$t('form.lastname')" />
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-group :label="$t('form.email')">
            <b-form-input v-model="user.login" :placeholder="$t('form.email')" disabled/>
          </b-form-group>
          <b-list-group class="mb-3">
            <b-list-group-item class="d-flex align-items-center justify-content-between p-2">
              <span>Newsletter</span>
              <b-form-checkbox v-model="user.newsletter" switch />
            </b-list-group-item>
          </b-list-group>
          <template v-if="showPassword">
            <hr>
            <b-form-group :label="$t('form.oldPassword')">
              <b-form-input type="password" v-model="user.oldPassword" :placeholder="$t('form.password')" />
            </b-form-group>
            <b-form-group :label="$t('form.newPassword')">
              <b-form-input type="password" v-model="user.newPassword1" :placeholder="$t('form.password')" />
            </b-form-group>
            <b-form-group :label="$t('form.newPassword')">
              <b-form-input type="password" v-model="user.newPassword2" :placeholder="$t('form.password')" />
              <div v-if="user.newPassword1 != user.newPassword2" class="badge badge-danger">{{ $t('form.badPassword') }}</div>
            </b-form-group>

          </template>
          <template v-else>
            <div class="mb-2">
              <b-link @click="showPassword = true">
                {{ $t('form.update') }}
              </b-link>
            </div>
          </template>
          <b-button variant="primary" type="submit" block :disabled="!isValid && showPassword == true">
            <font-awesome-icon :icon="[$store.state.family, 'save']" />
            {{ $t('form.save') }}
          </b-button>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script  lang="ts">
import { mapState, mapActions } from 'vuex'
import api from '@/api'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      user: {
        login: '',
        firstname: '',
        lastname: '',
        newsletter: false,
        oldPassword: '',
        newPassword1: '',
        newPassword2: '',
      },
      showPassword: false
    }
  },
  computed: {
    ...mapState('auth', { storedUser: 'user' }),
    isValid (): boolean {
      return this.user.newPassword1.length > 0 && this.user.newPassword2.length > 0
        && this.user.newPassword1 === this.user.newPassword2
    }
  },
  methods: {
    ...mapActions('auth', ['updateSession']),
    onSubmit(): void {
      api.user.updateUser(this.user)
        .finally(() => {
          this.updateSession()
          this.$root.$bvToast.toast(this.$t('toast.body'), {
            variant: 'success',
            title: this.$t('toast.title')
          })
        })
    }
  },
  created () {
    this.user.login = this.storedUser.login
    this.user.firstname = this.storedUser.firstname
    this.user.lastname = this.storedUser.lastname
    this.user.newsletter = this.storedUser.newsletter
  }
})
</script>

<i18n>
{
  "fr": {
    "welcome": "Bienvenue {name}",
    "form": {
      "firstname": "Prénom",
      "lastname": "Nom",
      "email": "Adresse email",
      "password": "Mot de passe",
      "newPassword": "Nouveau mot de passe",
      "oldPassword": "Mot de passe actuel",
      "badPassword": "Les mots de passe ne correspondent pas",
      "update": "Mettre à jour le mot de passe",
      "save": "Enregistrer"
    },
    "toast": {
      "title": "Enregistré",
      "body": "Tous les changements ont été enregistrés avec succès."
    }
  },
  "en": {
    "welcome": "Welcome {name}",
    "form": {
      "firstname": "Firstname",
      "lastname": "Lastname",
      "email": "Login",
      "password": "Password",
      "newPassword": "New password",
      "oldPassword": "Current password",
      "badPassword": "Passwords are not equal",
      "update": "Update password",
      "save": "Save"
    },
    "toast": {
      "title": "Saved",
      "body": "Edits have been saved."
    }
  }
}
</i18n>
