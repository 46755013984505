export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "statistics": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques"])}
        },
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
        "registredNewsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrits à la newsletter"])},
        "activeUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs actifs"])}
      },
      "en": {
        "statistics": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])}
        },
        "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
        "registredNewsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed to newsletter"])},
        "activeUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active users"])}
      }
    }
  })
}
