<template>
  <div class="mb-4  d-flex">
    <div class="row no-gutters align-items-start w-100 m-auto">
      <div class="col-3">
        <!-- Panel Profil -->
        <b-card class="text-center h-100 mb-3 shadow-sm" >
          <b-card-text>
            <b-link href="#" :to="{ name: 'Home.profile' }">
              <div class=" text-dark display-2 mb-3">
                <font-awesome-icon :icon="[$store.state.family, 'user-circle']" />
              </div>
              <b-form-group>
                <h4 class="mb-0 text-dark">
                  {{ $t('greeting', { name: (storedUser.firstname + ' ' + storedUser.lastname) }) }}
                </h4>
              </b-form-group>
              <b-button v-if="!user.newsletter">
                {{ $t('newsletterSubscription') }}
              </b-button>
            </b-link>
            <p v-if="user.newsletter">
              {{ $t('subscribed') }}
            </p>
          </b-card-text>
        </b-card>
        <b-card v-if="user.admin" :title="$t('card.admin.title')" class="mb-3 shadow-sm">
          <b-link :to="{ name: 'Admin.Users.New' }" variant="primary" class=" mb-1 d-flex align-items-center w-100 text-left">
            <font-awesome-icon :icon="[$store.family, 'plus']" class="mr-2" />
            {{ $t('card.admin.addUser') }}
            <div class="badge badge-light ml-auto mt-1">Total : {{ getCountUsers }}</div>
          </b-link>
          <b-link :to="{ name: 'Admin.Users.New' }" variant="primary" class=" mb-1 d-flex align-items-center w-100 text-left">
            <font-awesome-icon :icon="[$store.family, 'plus']" class="mr-2" />
            {{ $t('card.admin.addCompany') }}
            <div class="badge badge-light ml-auto mt-1">Total : {{ getCountCompany }}</div>
          </b-link>
          <b-link :to="{ name: 'Admin.Newsletter.New' }" variant="primary" class="mb-1 d-flex align-items-center text-left">
            <font-awesome-icon :icon="[$store.family, 'plus']" class="mr-2" />
            Newsletter
            <div class="badge badge-light ml-auto mt-1">Total : {{getCountUsersNewsletter}}</div>
          </b-link>
        </b-card>
        <b-card v-if="user.admin" title="Site AB+" class="p-0 mb-3 shadow-sm" >
          <b-link :href="siteBaseURl + '/wp-admin/post-new.php'" target="_blank" variant="primary" class="mb-1 d-flex align-items-center w-100 text-left">
            <font-awesome-icon :icon="[$store.family, 'plus']" class="mr-2" />
            {{ $t('actualitesArticles') }}
          </b-link>
          <b-link :href="siteBaseURl + '/wp-admin/post-new.php?post_type=page'" target="_blank" variant="primary" class="mb-1 d-flex align-items-center w-100 text-left">
            <font-awesome-icon :icon="[$store.family, 'plus']" class="mr-2" />
            Page
          </b-link>
          <b-link :href="siteBaseURl + '/wp-admin/plugins.php'" target="_blank" variant="primary" class="mb-1 d-flex align-items-center w-100 text-left">
            <font-awesome-icon :icon="[$store.family, 'puzzle-piece']" class="mr-1" />
            Extensions
          </b-link>
        </b-card>
        <b-card v-if="false" class="shadow-sm" :title="$t('card.demonstration.title')">
          <b-card-text>
            <b-link class=" badge badge-primary mr-2" :to="{name: 'Home.mng'}"># MNG Example</b-link>
            <b-link class=" badge badge-primary mr-2"># MNG 6.1 Example</b-link>
            <b-link class=" badge badge-primary mr-2"># MNG 6.0 Example</b-link>
          </b-card-text>
        </b-card>
      </div>
      <div class="col-9">
        <div class="col-12 mb-3">

          <widget-statistics />
          <widget-blog />
          <!-- <widget-articles /> -->

        </div>
        <div v-if="user.company.product == 'SOLU-QIQ'" class="col-12">
          <div class="row">
            <!-- Panel Dernier patch Base -->
            <div class="col-6">
              <b-card class="shadow-sm mb-3" :title="$t('card.lastPatch.title')">
                <b-card-text>
                  <b-list-group-item
                    @click="$router.push({ name: 'Home.Patchnote' })"
                    v-for="(item, index) in Patchnote['versions_SQBase']"
                    :key="index"
                    class="d-flex align-items-center">
                      {{ item.version }}
                      <span class="ml-auto badge badge-primary">{{ item.date }}</span>
                  </b-list-group-item>
                </b-card-text>
              </b-card>
            </div>
            <!-- Panel Dernier Patch web -->
            <div class="col-6">
              <b-card class="shadow-sm" :title="$t('card.lastPatchWeb.title')">
                <b-card-text>
                  <b-list-group-item
                    @click="$router.push('Patchenote')"
                    v-for="(item, index) in Patchnote['versions_SQWeb']"
                    :key="index"
                    class="d-flex align-items-center">
                      {{ item.version }}
                      <span class="ml-auto badge badge-primary">{{ item.date }}</span>
                  </b-list-group-item>
                </b-card-text>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script  lang="ts">
import { mapState } from 'vuex'
import api from '@/api'
import WidgetStatistics from './widget/WidgetStatistics.vue'
import widgetBlog from './widget/widgetBlog.vue'
import WidgetArticles from './widget/WidgetArticles.vue'


import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    WidgetStatistics,
    widgetBlog
  },
  computed: {
    ...mapState('auth', { storedUser: 'user' }),
    ...mapState('auth', ['loggedIn', 'user']),
    getCountUsers(): number {
      return this.users.length
    },
    getCountCompany(){
      return this.company.length
    },
    getCountUsersNewsletter(): number{
      const users = this.users.filter((u: { newsletter: boolean}) => u.newsletter == true)
      return users.length
    },
    getCountactiveUserss(): number {
      const users = this.users.filter((u: { active: boolean}) => u.active == true)
      return users.length
    }
  },
   data(){
    return {
      versionList: [],
      Patchnote:[],
      actualites:[],
      siteBaseURl: '',
      loadNews: true,
      loadCount: false,
      users:[],
      company: [],
      countUser:0,
      newsletterCount:0,
      newsletterInscri:0
    }
  },
  created () {
    this.loadNews = true
    api.patchnote.getListVersion()
      .then(response => response.data)
      .then(data => {
        if (data.status) {
          this.versionList = data.results.reverse()
           api.patchnote.getPatchnote(this.versionList[0]['version'])
          .then(response => response.data)
          .then(data => {
            if (data.status) {
              this.Patchnote = data.results
            }
          })
          this.loadNews = false
        }
      })
    if (this.user.admin) {
      // Count user
      api.admin.getAllUsers()
      .then(response => response.data)
      .then(data => {
        if (data.status) {
          this.users = data.results
        }
      })

      api.admin.getAllCompanies()
      .then(response => response.data)
      .then(data => {
        if (data.status) {
          this.company = data.results
        }
      })
    }
  },
})
</script>

<i18n>
{
  "fr": {
    "users": "Utilisateurs",
    "registredNewsletter": "Inscrits à la newsletter",
    "activeUsers":"Utilisateurs actifs",
    "greeting": "Bonjour {name}",
    "newsletterSubscription": "Inscrivez-vous à la newsletter",
    "subscribed": "Vous êtes inscrit à la newsletter",
    "actualitesArticles": "Actualités / Articles",
    "card": {
      "demonstration": {
        "title": "Démonstrations"
      },
      "lastPatch": {
        "title": "Dernier patch : SOLU-QIQ"
      },
      "lastPatchWeb": {
        "title": "Dernier patch : Web Contributeur"
      },
      "admin": {
        "title": "Administration",
        "addUser": "Utilisateurs",
        "addCompany": "Entreprises"
      }
    }
  },
  "en": {
    "users": "Users",
    "registredNewsletter": "Registered to the newsletter",
    "activeUsers":"Active users",
    "greeting": "Hello {name}",
    "newsletterSubscription": "Subscribe to the newsletter",
    "subscribed": "You are subscribed to the newswletter",
    "actualitesArticles": "Actualities / Articles",
    "card": {
      "demonstration": {
        "title": "Demonstrations"
      },

      "lastPatch": {
        "title": "Last patch: SOLU-QIQ"
      },
      "lastPatchWeb": {
        "title": "Last patch: Web Contributeur"
      },
      "admin": {
        "title": "Administration",
        "addUser": "Users",
        "addCompany": "Companies"
      }

    }
  }
}
</i18n>
