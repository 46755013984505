import instance from '@/api/instance'

export default {
  getContent: (path: string) => {
    return instance.get(`/explorer/folder?p=${path}`)
  },
  createFolder: ((name: string, path: string) => {
    return instance.post('/explorer/folder', {
      name,
      path
    })
  }),
  uploadFile: (
    {
      formData,
      path,
      onProgress,
      onDone,
      onError,
    }:
    {
      formData: FormData;
      path: string;
      onProgress: CallableFunction;
      onDone: CallableFunction;
      onError: CallableFunction;
    }
  ) => {
    return instance.post(
      `/explorer/upload?p=${encodeURIComponent(path)}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent: ProgressEvent) => {
          onProgress({
            progress: (Math.round((progressEvent.loaded / progressEvent.total) * 100))
          })
        }
      }
    ).then(() => {
      onDone()
    }).catch(() => {
      onError()
    })
  },
  delete: (path: string) => {
    return instance.delete(`/explorer/delete?path=${encodeURIComponent(path)}`)
  }
}
