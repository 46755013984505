import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/Default.vue'),
    meta: {
      auth: true,
      admin: true
    },
    children: [
      {
        path: '',
        name: 'Admin',
        component: () => import(/* webpackChunkName: "admin" */ '@/components/pages/Admin.vue'),
        redirect: { name: 'Admin.Users' },
        children: [
          {
            path: 'new',
            name: 'Admin.Users.New',
            component: () => import(/* webpackChunkName: "admin" */ '@/components/pages/admin/NewUser.vue'),
          },
          {
            path: 'user/:id',
            name: 'Admin.Users.User',
            component: () => import(/* webpackChunkName: "admin" */ '@/components/pages/admin/User.vue'),
            props: true
          },
          {
            path: 'users',
            name: 'Admin.Users',
            component: () => import(/* webpackChunkName: "admin" */ '@/components/pages/admin/Users.vue')
          },
          {
            path: 'companies',
            component: () => import(/* webpackChunkName: "admin" */ '@/views/Empty.vue'),
            children: [
              {
                path: '',
                name: 'Admin.Companies',
                component: () => import(/* webpackChunkName: "admin" */ '@/components/pages/admin/Companies.vue'),
              },
              {
                path: 'new',
                name: 'Admin.Companies.New',
                component: () => import(/* webpackChunkName: "admin" */ '@/components/pages/admin/NewCompany.vue'),
              },
              {
                path: ':id',
                name: 'Admin.Company',
                component: () => import(/* webpackChunkName: "admin" */ '@/components/pages/admin/Company.vue')
              }
            ]
          },
          {
            path: 'faq',
            name: 'Admin.faq',
            component: () => import(/* webpackChunkName: "admin" */ '@/components/pages/admin/faq.vue')
          },
          {
            path: 'newsletter',
            component: () => import(/* webpackChunkName: "admin" */ '@/views/Empty.vue'),
            children: [
              {
                path: '',
                name: 'Admin.Newsletter',
                component: () => import(/* webpackChunkName: "admin" */ '@/components/pages/admin/Newsletters.vue')
              },
              {
                path: 'new',
                name: 'Admin.Newsletter.New',
                component: () => import(/* webpackChunkName: "admin" */ '@/components/pages/admin/Writer.vue')
              },
              {
                path: ':id',
                name: 'Admin.Newsletter.Detail',
                component: () => import(/* webpackChunkName: "admin" */ '@/components/pages/admin/Newsletter.vue'),
                props: true
              }
            ]
          }
        ]
      }
    ]
  }
] as RouteRecordRaw []
