<template>
  <b-overlay v-if="user.admin" :show="load" rounded="sm">
    <b-card class="mb-3" :title="$t('statistics.title')">
      <b-card-text class="text-center">
        <div class="row">
          <div class="col-4">

            <h3 class="w-100 rounded p-3 badge-success">
              <font-awesome-icon class="mr-1" :icon="[$store.state.family, 'users']" />
              <span>{{getCountUsers}}</span>
              <h6 class="w-100">{{ $t('users') }}</h6>
            </h3>
          </div>
          <div class="col-4">
            <h3 class="w-100 rounded p-3 badge-info">
              <font-awesome-icon class="mr-1" :icon="[$store.state.family, 'newspaper']" />
              <span>{{getCountUsersNewsletter}}</span>
              <h6 class="w-100">{{ $t('registredNewsletter') }}</h6>
            </h3>
          </div>
          <div class="col-4">
            <h3 class="w-100 rounded p-3 badge-primary">
              <font-awesome-icon class="mr-1" :icon="[$store.state.family, 'check']" />
              <span>{{getCountactiveUserss}}</span>
              <h6 class="w-100">{{ $t('activeUsers') }}</h6>
            </h3>
          </div>
        </div>
      </b-card-text>
    </b-card>

  </b-overlay>
</template>

<script  lang="ts">
import { mapState } from 'vuex'
import api from '@/api'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      load: false,
      users: [],
      company: []
    }
  },
  created () {
    if (this.user.admin) {
      // Count user
      api.admin.getAllUsers()
      .then(response => response.data)
      .then(data => {
        if (data.status) {
          this.users = data.results
        }
      })

      api.admin.getAllCompanies()
      .then(response => response.data)
      .then(data => {
        if (data.status) {
          this.company = data.results
        }
      })
    }
  },
  computed: {
    ...mapState('auth', { storedUser: 'user' }),
    ...mapState('auth', ['loggedIn', 'user']),
    getCountUsers(): number {
      return this.users.length
    },
    getCountCompany(){
      return this.company.length
    },
    getCountUsersNewsletter(): number{
      const users = this.users.filter((u: { newsletter: boolean}) => u.newsletter == true)
      return users.length
    },
    getCountactiveUserss(): number {
      const users = this.users.filter((u: { active: boolean}) => u.active == true)
      return users.length
    }
  }
})
</script>

<i18n>
  {
    "fr": {
      "statistics": {
        "title": "Statistiques"
      },
      "users": "Utilisateurs",
      "registredNewsletter": "Inscrits à la newsletter",
      "activeUsers":"Utilisateurs actifs"
    },
    "en": {
      "statistics": {
        "title": "Statistics"
      },
        "users": "Users",
        "registredNewsletter": "Subscribed to newsletter",
        "activeUsers":"Active users"
    }
  }
</i18n>
