import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "font-weight-bold d-flex justify-content-between" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col-md-3" }
const _hoisted_6 = {
  class: "d-flex flex-column cursor-pointer",
  for: "file"
}
const _hoisted_7 = {
  key: 0,
  class: "border bg-200 w-100 rounded"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_vue_editor = _resolveComponent("vue-editor")!
  const _component_b_form_file = _resolveComponent("b-form-file")!
  const _component_b_aspect = _resolveComponent("b-aspect")!

  return (this.user.admin)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("form", {
          onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
        }, [
          _createElementVNode("h3", _hoisted_2, [
            (_ctx.newArticle)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('titleNewArticle')), 1)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('titleEditArticle')), 1)
                ], 64)),
            _createElementVNode("div", null, [
              _createVNode(_component_b_button, {
                class: "mr-1",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: [_ctx.$store.state.family, 'arrow-left']
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('cancelButton')), 1)
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_button, {
                type: "submit",
                variant: "primary",
                disabled: !_ctx.isValid
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: [_ctx.$store.state.family, 'paper-plane']
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('publishButton')), 1)
                ], undefined, true),
                _: 1
              }, 8, ["disabled"])
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_b_form_group, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_input, {
                    modelValue: _ctx.title,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.title) = $event)),
                    placeholder: "Titre",
                    size: "lg"
                  }, null, 8, ["modelValue"])
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_form_group, null, {
                default: _withCtx(() => [
                  _createVNode(_component_vue_editor, {
                    modelValue: _ctx.content,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.content) = $event)),
                    class: "bg-white"
                  }, null, 8, ["modelValue"])
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_form_group, { class: "text-right" })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_b_form_file, {
                modelValue: _ctx.thumbnail,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.thumbnail) = $event)),
                id: "file",
                class: "d-none",
                accept: "image/*"
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_6, [
                (_ctx.thumbnail === null)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_b_aspect, {
                        aspect: "16:9",
                        class: "d-flex text-center align-items-center"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('noImageText')), 1)
                        ], undefined, true),
                        _: 1
                      })
                    ]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("div", {
                        style: _normalizeStyle(_ctx.style),
                        class: "rounded-left"
                      }, [
                        _createVNode(_component_b_aspect, { aspect: "16:12" })
                      ], 4),
                      _createElementVNode("a", {
                        href: "#",
                        onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.thumbnail = null), ["prevent"])),
                        class: "text-muted text-center w-100"
                      }, _toDisplayString(_ctx.$t('deleteImage')), 1)
                    ], 64))
              ])
            ])
          ])
        ], 32)
      ]))
    : _createCommentVNode("", true)
}