import Default from '@/views/Default.vue'
import Articles from '@/components/pages/articles/Articles.vue'
import ArticlesEditor from '@/components/pages/articles/ArticlesEditor.vue'
import Articleview from '@/components/pages/articles/ArticleView.vue'

export default [
  {
    path: '/articles',
    component: Default,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'Articles',
        component: Articles
      },
      {
        path: 'new',
        name: 'NewArticle',
        component: ArticlesEditor,
        props: {
          newArticle: true
        }
      },
      {
        path: ':id',
        name: 'Article',
        component: Articleview,
      },
      {
        path: ':id/edit',
        name: 'ArticleEdit',
        component: ArticlesEditor,
        props: route => ({
          id: route.params.id,
          newArticle: false
        })
      }
    ]
  }
]
