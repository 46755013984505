export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "titleNewArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel article"])},
        "titleEditArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition de l'article"])},
        "publishButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier"])},
        "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "addToastContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'article a bien été publié."])},
        "updateToastContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'article a bien été mis à jour."])},
        "errorToastContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue."])},
        "noImageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune image sélectionnée"])},
        "deleteImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'illustration"])}
      },
      "en": {
        "titleNewArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New article"])},
        "titleEditArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article edition"])},
        "publishButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
        "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "addToastContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The article has been published."])},
        "updateToastContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The article has been updated."])},
        "errorToastContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured."])},
        "noImageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No image selected"])},
        "deleteImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete thumbnail"])}
      }
    }
  })
}
