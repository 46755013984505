<template>
  <div>

    <div width="100%"
    style="width: 100vw; background: white; height: 93.5vh; left: 0px; top: 60px; border-top: 1px solid rgb(222, 226, 230);z-index: 1;"
    class="position-absolute d-flex">
      <div class="m-auto spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <iframe
      width="100%"
      style=" height: 93.5vh; left: 0px; top: 60px; border-top: 1px solid rgb(222, 226, 230);z-index: 2;"
      class="position-absolute"
      :src="url"
      frameborder="0"
    ></iframe>


    <b-link @click="goToBack()" target="_blank" :href="url" variant="primary" class="btn btn-primary position-absolute"
    style="top: 67px; right: 64px; z-index: 2;">
      {{ $t('openInBrowser') }}
    </b-link>

    <b-button @click="goToBack()" class="position-absolute"
    style="top: 67px;right: 22px;z-index: 2;">
      <span aria-hidden="true">&times;</span>
    </b-button>
  </div>
</template>

<script  lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      url: 'https://client.abplussoftware.fr/demo/'
    }
  },
  methods:{
    goToBack(){
      this.$router.go(-1)
    }
  }
})
</script>

<i18n>
{
  "fr": {
    "openInBrowser": "Ouvrir le MNG dans le navigateur"
  },
  "en": {
    "openInBrowser": "Open MNG in the browser"
  }
}
</i18n>
