import instance from '@/api/instance'

// Import des types de données
import { Credentials } from '@/types/modules/forms'

export default {
  checkSession: () => {
    return instance.get('/check-session')
  },
  login: (payload: Credentials) => {
    return instance.post('/login', payload)
  },
  logout: () => {
    return instance.post('/logout')
  },
  requestPassword: (email: string) => {
    return instance.post('/forgot-password', {
      email: email
    })
  },
  resetPassword: (payload: string, password: string) => {
    const exploded = payload.split('#')
    return instance.put('/reset-password', {
      email: exploded[0],
      password: password,
      token: exploded[1]
    })
  }
}
