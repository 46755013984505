<template>
  <b-navbar
    class="border-bottom shadow-sm"
    type="dark"
    variant="dark"
    toggleable="lg"
  >
    <b-navbar-brand :to="{ name: 'Home'}">
      <b-img
        class="mr-1"
        src="@/assets/logo-white.png"
        alt="AB+ SOFTWARE"
        :style="{ height: '30px' }"
      />
      {{ $t('app.name') }}
    </b-navbar-brand>
    <b-navbar-toggle target="main-navbar-collapse"></b-navbar-toggle>
    <b-collapse
      class="w-100"
      id="main-navbar-collapse"
      is-nav
    >
      <template v-if="loggedIn">
        <b-navbar-nav>
          <b-nav-item
            :to="{ name: 'Home' }"
            exact-active-class="active"
          >
            <font-awesome-icon :icon="[$store.state.family, 'home']" />
            {{ $t('link.home') }}
          </b-nav-item>
          <b-nav-item
            :to="{ name: 'Explorer', query: { path: ''} }"
            active-class="active"
          >
            <font-awesome-icon :icon="[$store.state.family, 'folder']" />
            {{ $t('link.explorer') }}
          </b-nav-item>
          <b-nav-item
            :to="{ name: 'Articles' }"
            active-class="active"
          >
            <font-awesome-icon :icon="[$store.state.family, 'newspaper']" />
            {{ $t('link.articles') }}
          </b-nav-item>
          <b-nav-item
            v-if="user.company.product == 'SOLU-QIQ' || user.admin"
            :to="{ name: 'Home.Patchnote' }"
            active-class="active"
          >
            <font-awesome-icon :icon="[$store.state.family, 'puzzle-piece']" />
            {{ $t('link.patchnote') }} {{ user.admin ? 'SOLU-QIQ' : '' }}
          </b-nav-item>
          <b-nav-item
            v-if="user.company.product == 'myCarto' || user.admin"
            :to="{ name: 'Home.PatchnoteMycarto' }"
            active-class="active"
          >
            <font-awesome-icon :icon="[$store.state.family, 'puzzle-piece']" />
            {{ $t('link.patchnote') }} {{ user.admin ? 'myCarto' : '' }}
          </b-nav-item>
          <b-nav-item
            :to="{ name: 'Home.faq' }"
            active-class="active"
          >
            <font-awesome-icon :icon="[$store.state.family, 'folder']" />
            {{ $t('link.faq') }}
          </b-nav-item>
          <b-nav-item
            v-if="user.company.product == 'SOLU-QIQ'"
            :to="{ name: 'Home.mng' }"
            active-class="active"
          >
            <font-awesome-icon :icon="[$store.state.family, 'code-branch']" />
            {{ $t('link.mng') }}
          </b-nav-item>
        </b-navbar-nav>
      </template>
        <b-navbar-nav class="ml-auto">
          <template v-if="loggedIn">
            <b-nav-item
              variant="outline-primary"
              :href="getcontactUrl()"
            >
              <font-awesome-icon :icon="[$store.state.family, 'envelope']" />
              {{ $t('link.support') }}
            </b-nav-item>
          </template>
          <b-nav-item-dropdown right>
            <template v-slot:button-content>
              <template v-if="loggedIn">
                <font-awesome-icon :icon="[$store.state.family, 'user-circle']" />
                {{user.firstname}}
              </template>
              <template v-else>
                <font-awesome-icon :icon="[$store.state.family, 'cog']" />
              </template>
            </template>
            <template v-if="loggedIn">
              <b-dropdown-item href="#" :to="{ name: 'Home.profile' }">
                <font-awesome-icon :icon="[$store.state.family, 'user-circle']" fixed-width />
                {{ $t('link.profile') }}
              </b-dropdown-item>
              <b-dropdown-item href="#" @click="logout">
                <font-awesome-icon :icon="[$store.state.family, 'sign-out-alt']" fixed-width />
                {{ $t('link.logout') }}
              </b-dropdown-item>
              <b-dropdown-divider />
              <template v-if="user.admin">
              <b-dropdown-item :to="{ name: 'Admin' }" active-class="active">
              <font-awesome-icon :icon="[$store.state.family, 'user-shield']" fixed-width />
                {{ $t('link.admin') }}
              </b-dropdown-item>
              <b-dropdown-divider />
            </template>
            </template>
            <div class="px-2">
              <div class="form-row">
                <div class="col">
                  <b-button variant="white" @click="setLocale('en')" size="sm" block>
                    <b-img src="@/assets/flags/english.png" :style="{width: '20px'}"/>
                  </b-button>
                </div>
                <div class="col">
                  <b-button variant="white" @click="setLocale('fr')" size="sm" block>
                    <b-img src="@/assets/flags/french.png" :style="{width: '20px'}"/>
                  </b-button>
                </div>
              </div>
            </div>
          </b-nav-item-dropdown>
        </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script  lang="ts">

import { mapState, mapMutations, mapActions } from 'vuex'

import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    ...mapState('auth', ['loggedIn', 'user']),
    ...mapState(['family'])
  },
  methods: {
    ...mapMutations('locales', ['setLocale']),
    ...mapActions('auth', ['logout']),
    getcontactUrl(){

      let contactUrl = "mailto:mycarto.support@abplussoftware.fr?subject=Description brève du problème...&body=Description détaillée du problème...";

      if(this.user.company.product == "SOLU-QIQ"){
        contactUrl = "mailto:support@abplussoftware.fr?subject=Description brève du problème...&body=Description détaillée du problème...";
      }
      return contactUrl;
    }
  }
})
</script>

<i18n>
{
  "fr": {
    "link": {
      "language": "Langue",
      "logout": "Déconnexion",
      "home": "Tableau de bord",
      "admin": "Administration",
      "explorer": "Explorateur",
      "patchnote": "Notes de version",
      "faq": "FAQ",
      "mng": "Exemple de cartographie",
      "support": "Contactez le support",
      "profile": "Mon profil"
    }
  },
  "en": {
    "link": {
      "language": "Language",
      "logout": "Logout",
      "home": "Dashboard",
      "admin": "Admin",
      "explorer": "Explorer",
      "articles": "Articles",
      "patchnote": "Patchnote",
      "faq": "Q&A",
      "mng": "Cartography example",
      "support": "Contact support",
      "profile": "My profile"
    }
  }
}
</i18n>
