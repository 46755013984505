import instance from '@/api/instance'

export default {
  getContent: (dir: string) => {
    return instance.get('/folders?dir=' + dir)
  },
  uploadFile: (file: File, direc: string) => {
    const formData = new FormData();
    formData.append('file', file);

    const par = {
      File: formData,
      dir: direc
    }

    return instance.post('/upload-file', par, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    })
  }
}
