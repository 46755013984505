import instance from '@/api/instance'

export default {
  getPatchnote: (version: string) => {
    return instance.get('/Patchnote?version='+ version)
  },
  getListVersion: () => {
    return instance.get('/Patchnote/list')
  }
}
