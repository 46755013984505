import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "w-100 rounded p-3 badge-success" }
const _hoisted_4 = { class: "w-100" }
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = { class: "w-100 rounded p-3 badge-info" }
const _hoisted_7 = { class: "w-100" }
const _hoisted_8 = { class: "col-4" }
const _hoisted_9 = { class: "w-100 rounded p-3 badge-primary" }
const _hoisted_10 = { class: "w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_card_text = _resolveComponent("b-card-text")!
  const _component_b_card = _resolveComponent("b-card")!
  const _component_b_overlay = _resolveComponent("b-overlay")!

  return (_ctx.user.admin)
    ? (_openBlock(), _createBlock(_component_b_overlay, {
        key: 0,
        show: _ctx.load,
        rounded: "sm"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_card, {
            class: "mb-3",
            title: _ctx.$t('statistics.title')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_card_text, { class: "text-center" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("h3", _hoisted_3, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "mr-1",
                          icon: [_ctx.$store.state.family, 'users']
                        }, null, 8, ["icon"]),
                        _createElementVNode("span", null, _toDisplayString(_ctx.getCountUsers), 1),
                        _createElementVNode("h6", _hoisted_4, _toDisplayString(_ctx.$t('users')), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("h3", _hoisted_6, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "mr-1",
                          icon: [_ctx.$store.state.family, 'newspaper']
                        }, null, 8, ["icon"]),
                        _createElementVNode("span", null, _toDisplayString(_ctx.getCountUsersNewsletter), 1),
                        _createElementVNode("h6", _hoisted_7, _toDisplayString(_ctx.$t('registredNewsletter')), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("h3", _hoisted_9, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "mr-1",
                          icon: [_ctx.$store.state.family, 'check']
                        }, null, 8, ["icon"]),
                        _createElementVNode("span", null, _toDisplayString(_ctx.getCountactiveUserss), 1),
                        _createElementVNode("h6", _hoisted_10, _toDisplayString(_ctx.$t('activeUsers')), 1)
                      ])
                    ])
                  ])
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          }, 8, ["title"])
        ], undefined, true),
        _: 1
      }, 8, ["show"]))
    : _createCommentVNode("", true)
}