import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "ml-1" }
const _hoisted_2 = { class: "text-muted w-50 text-right" }
const _hoisted_3 = { class: "badge badge-300 w-50" }
const _hoisted_4 = { class: "ml-1" }
const _hoisted_5 = { class: "text-muted w-50 text-right" }
const _hoisted_6 = { class: "badge badge-300 w-25" }
const _hoisted_7 = { class: "badge badge-300 ml-2 w-50" }
const _hoisted_8 = { class: "text-center text-muted" }
const _hoisted_9 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_10 = { class: "border-bottom mb-3 small font-weight-bold" }
const _hoisted_11 = {
  key: 1,
  class: "mb-3"
}
const _hoisted_12 = { class: "border-bottom mb-3 small font-weight-bold" }
const _hoisted_13 = { class: "mb-3" }
const _hoisted_14 = { class: "border-bottom mb-3 small font-weight-bold" }
const _hoisted_15 = { class: "mb-3" }
const _hoisted_16 = { class: "border-bottom mb-3 small font-weight-bold" }
const _hoisted_17 = { class: "d-flex justify-content-between" }
const _hoisted_18 = { class: "text-truncate" }
const _hoisted_19 = { class: "d-flex justify-content-between" }
const _hoisted_20 = { class: "text-truncate" }
const _hoisted_21 = { class: "d-flex justify-content-between" }
const _hoisted_22 = { class: "text-truncate" }
const _hoisted_23 = { class: "mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_breadcrumb_item = _resolveComponent("b-breadcrumb-item")!
  const _component_b_breadcrumb = _resolveComponent("b-breadcrumb")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_form_row = _resolveComponent("b-form-row")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_link = _resolveComponent("b-link")!
  const _component_b_list_group_item = _resolveComponent("b-list-group-item")!
  const _component_b_list_group = _resolveComponent("b-list-group")!
  const _component_b_form_radio = _resolveComponent("b-form-radio")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_form_file = _resolveComponent("b-form-file")!
  const _component_file_upload = _resolveComponent("file-upload")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_input_group_append = _resolveComponent("b-input-group-append")!
  const _component_b_input_group = _resolveComponent("b-input-group")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_badge = _resolveComponent("b-badge")!
  const _component_b_progress = _resolveComponent("b-progress")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_form_row, { class: "align-items-center" }, {
      default: _withCtx(() => [
        _createVNode(_component_b_col, { class: "mb-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_breadcrumb, { class: "mb-0 px-3 py-1 border" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.path, (item, index) => {
                  return (_openBlock(), _createBlock(_component_b_breadcrumb_item, {
                    key: index,
                    active: item.active,
                    onClick: ($event: any) => (_ctx.changeDirectory(item.relativePath))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.text), 1)
                    ], undefined, true),
                    _: 2
                  }, 1032, ["active", "onClick"]))
                }), 128))
              ], undefined, true),
              _: 1
            })
          ], undefined, true),
          _: 1
        })
      ], undefined, true),
      _: 1
    }),
    _createVNode(_component_b_form_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_b_col, { class: "mb-3" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_list_group, { class: "mb-1" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedDirectories, (dir, index) => {
                  return (_openBlock(), _createBlock(_component_b_list_group_item, {
                    class: "px-3 py-2 d-flex justify-content-between align-items-center",
                    key: dir.relativePath + '-' + index,
                    action: ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['fas', 'folder'],
                          class: "text-600"
                        }),
                        _createElementVNode("span", _hoisted_1, [
                          _createVNode(_component_b_link, {
                            onClick: ($event: any) => (_ctx.changeDirectory(dir.relativePath))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(dir.name), 1)
                            ], undefined, true),
                            _: 2
                          }, 1032, ["onClick"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$filters.calendar(dir.lastEdit)), 1),
                        _createVNode(_component_b_link, {
                          class: "text-danger ml-2",
                          onClick: ($event: any) => (_ctx.remove(dir.relativePath))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, {
                              icon: [_ctx.$store.state.family, 'trash-alt'],
                              "fixed-width": ""
                            }, null, 8, ["icon"])
                          ], undefined, true),
                          _: 2
                        }, 1032, ["onClick"])
                      ])
                    ], undefined, true),
                    _: 2
                  }, 1024))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedFiles, (file, index) => {
                  return (_openBlock(), _createBlock(_component_b_list_group_item, {
                    class: "px-3 py-2 d-flex justify-content-between align-items-center",
                    key: file.name + '-' + index,
                    action: ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['fas', 'file'],
                          class: "text-600"
                        }),
                        _createElementVNode("span", _hoisted_4, [
                          _createVNode(_component_b_link, {
                            onClick: ($event: any) => (_ctx.download(file))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(file.name), 1)
                            ], undefined, true),
                            _: 2
                          }, 1032, ["onClick"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(file.size), 1),
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$filters.calendar(file.lastEdit)), 1),
                        _createVNode(_component_b_link, {
                          class: "text-danger ml-2",
                          onClick: ($event: any) => (_ctx.remove(file.path))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, {
                              icon: [_ctx.$store.state.family, 'trash-alt'],
                              "fixed-width": ""
                            }, null, 8, ["icon"])
                          ], undefined, true),
                          _: 2
                        }, 1032, ["onClick"])
                      ])
                    ], undefined, true),
                    _: 2
                  }, 1024))
                }), 128))
              ], undefined, true),
              _: 1
            }),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.info.used) + " / 1G ", 1)
          ], undefined, true),
          _: 1
        }),
        _createVNode(_component_b_col, {
          class: "text-700",
          md: "5",
          lg: "4",
          xl: "3"
        }, {
          default: _withCtx(() => [
            (_ctx.verifyQuota)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('orderBy.title')), 1),
                  _createVNode(_component_b_form_group, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_form_radio, {
                        modelValue: _ctx.orderBy,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.orderBy) = $event)),
                        modelModifiers: { number: true },
                        name: "orderBy",
                        value: "1"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('orderBy.name')), 1)
                        ], undefined, true),
                        _: 1
                      }, 8, ["modelValue"]),
                      _createVNode(_component_b_form_radio, {
                        modelValue: _ctx.orderBy,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.orderBy) = $event)),
                        modelModifiers: { number: true },
                        name: "orderBy",
                        value: "2"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('orderBy.date')), 1)
                        ], undefined, true),
                        _: 1
                      }, 8, ["modelValue"])
                    ], undefined, true),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.verifyQuota)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('upload')), 1),
                  _createVNode(_component_b_form_file, {
                    disabled: _ctx.info.used >= _ctx.getMaxFolderSizer(_ctx.info.used) ? true : false,
                    modelValue: _ctx.file,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.file) = $event)),
                    placeholder: _ctx.$t('upload'),
                    "drop-placeholder": _ctx.$t('drop'),
                    size: "sm",
                    onInput: _ctx.addToQueue
                  }, null, 8, ["disabled", "modelValue", "placeholder", "drop-placeholder", "onInput"]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.queue, (file, index) => {
                    return (_openBlock(), _createBlock(_component_file_upload, {
                      key: index,
                      file: file,
                      path: _ctx.selected,
                      onDone: _ctx.refresh
                    }, null, 8, ["file", "path", "onDone"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('newFolder')), 1),
              _createVNode(_component_b_form, {
                onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_group, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input_group, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_form_input, {
                            modelValue: _ctx.foldername,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.foldername) = $event)),
                            size: "sm",
                            placeholder: _ctx.$t('foldername')
                          }, null, 8, ["modelValue", "placeholder"]),
                          _createVNode(_component_b_input_group_append, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_button, {
                                class: "border border-400",
                                variant: "300",
                                size: "sm",
                                onClick: _ctx.createFolder
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_font_awesome_icon, {
                                    icon: [_ctx.$store.state.family, 'folder-plus']
                                  }, null, 8, ["icon"])
                                ], undefined, true),
                                _: 1
                              }, 8, ["onClick"])
                            ], undefined, true),
                            _: 1
                          })
                        ], undefined, true),
                        _: 1
                      })
                    ], undefined, true),
                    _: 1
                  })
                ], undefined, true),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('infos')), 1),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('folders')), 1),
                _createElementVNode("div", null, [
                  _createVNode(_component_b_badge, { variant: "300" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.directories.length), 1)
                    ], undefined, true),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('files')), 1),
                _createElementVNode("div", null, [
                  _createVNode(_component_b_badge, { variant: "300" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.files.length), 1)
                    ], undefined, true),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('used')), 1),
                _createElementVNode("div", null, [
                  _createVNode(_component_b_badge, { variant: "300" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.info.used) + " / 1G", 1)
                    ], undefined, true),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_b_progress, {
                  class: "w-100",
                  variant: _ctx.getQuotaColors,
                  value: _ctx.info.used,
                  max: _ctx.getMaxFolderSizer(_ctx.info.used),
                  animated: _ctx.getQuotaColors == 'danger' ? true : false,
                  style: { height: '.4rem' }
                }, null, 8, ["variant", "value", "max", "animated"])
              ])
            ])
          ], undefined, true),
          _: 1
        })
      ], undefined, true),
      _: 1
    })
  ]))
}