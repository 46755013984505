import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "app",
  class: "d-flex flex-column",
  style: {minHeight: '100vh'}
}
const _hoisted_2 = { class: "navbar-container" }
const _hoisted_3 = { class: "content-container flex-grow-1" }
const _hoisted_4 = { class: "footer-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_navbar = _resolveComponent("main-navbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_main_footer = _resolveComponent("main-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_main_navbar)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_main_footer)
    ])
  ]))
}