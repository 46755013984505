<template>
  <div>
    <b-form-row class="align-items-center">
      <b-col class="mb-2">
        <b-breadcrumb class="mb-0 px-3 py-1 border">
          <b-breadcrumb-item
            v-for="(item, index) in path" :key="index"
            :active="item.active"
            @click="changeDirectory(item.relativePath)">
            {{ item.text }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col class="mb-3">
        <b-list-group class="mb-1">
          <b-list-group-item
            class="px-3 py-2 d-flex justify-content-between align-items-center"
            v-for="(dir, index) in orderedDirectories"
            :key="dir.relativePath + '-' + index"
            action
            >
            <span>
              <font-awesome-icon :icon="['fas', 'folder']" class="text-600" />
              <span class="ml-1">
                <b-link @click="changeDirectory(dir.relativePath)">
                  {{ dir.name }}
                </b-link>
              </span>
            </span>
            <div class="text-muted w-50 text-right">
              <div class="badge badge-300 w-50">
                {{ $filters.calendar(dir.lastEdit) }}
              </div>
              <b-link class="text-danger ml-2" @click="remove(dir.relativePath)">
                <font-awesome-icon
                  :icon="[$store.state.family, 'trash-alt']"
                  fixed-width
                   />
              </b-link>
            </div>
          </b-list-group-item>
          <b-list-group-item
            class="px-3 py-2 d-flex justify-content-between align-items-center"
            v-for="(file, index) in orderedFiles"
            :key="file.name + '-' + index"
            action
            >
            <span>
              <font-awesome-icon :icon="['fas', 'file']" class="text-600" />
              <span class="ml-1">
                <b-link @click="download(file)">
                  {{ file.name }}
                </b-link>
              </span>
            </span>
            <div class="text-muted w-50 text-right">
              <div class="badge badge-300 w-25">
                {{ file.size }}
              </div>
              <div class="badge badge-300 ml-2 w-50">
                {{ $filters.calendar(file.lastEdit) }}
              </div>
              <b-link class="text-danger ml-2" @click="remove(file.path)">
                <font-awesome-icon
                  :icon="[$store.state.family, 'trash-alt']"
                  fixed-width
                   />
              </b-link>
            </div>
          </b-list-group-item>
        </b-list-group>
        <div class="text-center text-muted">
          {{ info.used }} / 1G
        </div>
      </b-col>
      <b-col class="text-700" md="5" lg="4" xl="3">
        <div v-if="verifyQuota" class="mb-3">
          <div class="border-bottom mb-3 small font-weight-bold">
            {{ $t('orderBy.title') }}
          </div>
          <b-form-group>
            <b-form-radio v-model.number="orderBy" name="orderBy" value="1">{{ $t('orderBy.name') }}</b-form-radio>
            <b-form-radio v-model.number="orderBy" name="orderBy" value="2">{{ $t('orderBy.date') }}</b-form-radio>
          </b-form-group>
        </div>
        <div v-if="verifyQuota" class="mb-3">
          <div class="border-bottom mb-3 small font-weight-bold">
            {{ $t('upload') }}
          </div>
          <b-form-file
            :disabled="info.used >= getMaxFolderSizer(info.used) ? true : false"
            v-model="file"
            :placeholder="$t('upload')"
            :drop-placeholder="$t('drop')"
            size="sm"
            @input="addToQueue"
            />
          <file-upload
            v-for="(file, index) in queue"
            :key="index"
            :file="file"
            :path="selected"
            @done="refresh" />
        </div>
        <div class="mb-3">
          <div class="border-bottom mb-3 small font-weight-bold">
            {{ $t('newFolder') }}
          </div>
          <b-form @submit.prevent="">
            <b-form-group>
              <b-input-group>
                <b-form-input v-model="foldername" size="sm" :placeholder="$t('foldername')" />
                <b-input-group-append>
                  <b-button class="border border-400" variant="300" size="sm" @click="createFolder">
                    <font-awesome-icon :icon="[$store.state.family, 'folder-plus']" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-form>
        </div>
        <div class="mb-3">
          <div class="border-bottom mb-3 small font-weight-bold">
            {{ $t('infos') }}
          </div>
          <div class="d-flex justify-content-between">
            <div class="text-truncate">
              {{ $t('folders') }}
            </div>
            <div>
              <b-badge variant="300">{{ directories.length }}</b-badge>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="text-truncate">
              {{ $t('files') }}
            </div>
            <div>
              <b-badge variant="300">{{ files.length }}</b-badge>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="text-truncate">
              {{ $t('used') }}
            </div>
            <div>
              <b-badge variant="300">{{ info.used }} / 1G</b-badge>
            </div>
          </div>

          <div class="mt-1">
            <b-progress
              class="w-100"
              :variant="getQuotaColors"
              :value="info.used"
              :max="getMaxFolderSizer(info.used)"
              :animated="getQuotaColors == 'danger' ? true : false"
              :style="{ height: '.4rem' }"
              />
          </div>

        </div>
      </b-col>
    </b-form-row>
  </div>
</template>

<script  lang="ts">
import api from '@/api'
import instance from '@/api/instance'
import FileUpload from '@/components/pages/explorer/FileUpload.vue'

interface BreadcrumbItem {
  text: string;
  relativePath: string;
  active?: boolean;
}

interface FinderItem {
  name: string;
  relativePath: string;
  path: string;
}

import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      directories: [],
      files: [],
      folders: [],
      file: null as unknown,
      foldername: '',
      selected: this.$route.query.path || '',
      queue: [] as File[],
      info: {
        used: '',
        remaining: 0
      },
      orderBy: 1
    }
  },
  computed: {
    path (): BreadcrumbItem[] {
      let selected = ''
      // @ts-ignore
      if (typeof this.selected == 'string') {
        selected = (this.selected as string)
      // @ts-ignore
      } else if (typeof this.selected == 'number') {
        selected = this.selected.toString()
      }

      const tmpPath = 'root' + (selected.length > 0 ? '/' : '') + selected

      const tmpPath2 = tmpPath.toString().split('/')

      const path: BreadcrumbItem[] = []

      tmpPath2.map((folder, index) => {
        path.push({
          text: folder,
          relativePath: tmpPath2.filter((f, i) => (i <= index && i > 0)).join('/'),
          active: ((index + 1) >= tmpPath2.length)
        })
      })

      return path
    },
    verifyQuota(){
      let used = this.info.used.replace('M','')
      used = used.replace('K','')
      used = used.replace('G','')
      const quota = parseInt(used)
      if (quota >= 925) {
        return false
      }else{
        return true
      }
    },
    getQuotaColors(){
      const used = this.info.used.replace('M','')
      const quota = parseInt(used)
      if (quota >= 829) {
        return 'danger'
      }else if (quota >= 650) {
        return 'warning'
      }else if (quota >= 1.1) {
        return 'danger'
      }else{
        return 'primary'
      }
    },
    orderedDirectories (): Array<object> {
      if (this.orderBy === 1) {
        return this.directories.slice().sort((a, b): number => {
          return (a['name'] as string).localeCompare(b['name'], 'fr', { ignorePunctuation: true })
        })
      } else {
        return this.directories.slice().sort((a, b): number => {
          return b['lastEdit'] - a['lastEdit']
        })
      }
    },
    orderedFiles (): Array<object> {
      if (this.orderBy === 1) {
        return this.files.slice().sort((a, b): number => {
          return (a['name'] as string).localeCompare(b['name'], 'fr', { ignorePunctuation: true })
        })
      } else {
        return this.files.slice().sort((a, b): number => {
          return b['lastEdit'] - a['lastEdit']
        })
      }
    }
  },
  methods: {
    changeDirectory (to: string): void {
      this.selected = to
      this.$router.push({ name: 'Explorer', query: { path: to } })
      this.refresh()
    },
    refresh (): void {
      api.explorer.getContent((this.selected as string))
        .then(response => response.data)
        .then(data => {
          if (data.status) {
            this.directories = data.results.directories
            this.files = data.results.files
            this.info = {
              used: data.results.info.rootSize,
              remaining: data.results.info.remainingSpace
            }
          }
        })
    },
    download (file: FinderItem): void {
      window.open(
        instance.defaults.baseURL
        + '/download?f='
        + encodeURIComponent(file.path)
      )
    },
    createFolder () {
      if (this.foldername.length > 0) {
        api.explorer.createFolder(this.foldername, (this.selected as string))
          .finally(() => {
            this.refresh()
          })
      }
    },
    addToQueue (file: File): void {
      if (file !== null) {
        if (
          ((file as File).size < this.info.remaining)
          || this.$store.state.auth.user.admin
        ) {
          this.queue.push(file)
        } else {
          this.$root.$bvToast.toast((this.$t('toast.body') as string), {
            variant: 'danger',
            title: this.$t('toast.title') as string
          })
        }
      }
    },
    remove (path: string) {
      const r = confirm(this.$t('confirm') as string);
      if (r == true) {
        api.explorer.delete(path)
        .finally(() => {
          this.refresh()
        })
      }
    },
    getMaxFolderSizer(size: string): number{
      if (size.includes('K')) {
        return 1000000
      }else if (size.includes('M')) {
        return 1000
      }else{
        return 1
      }
    }
  },
  created () {
    this.refresh()
  },
  components: {
    FileUpload
  },
  watch: {
    '$route' (value) {
      if (this.selected != value.query.path) {
        this.selected = value.query.path
      }
    }
  }
})
</script>

<i18n>
{
  "fr": {
    "upload": "Envoyer un fichier",
    "drop": "Déposer le fichier ici...",
    "refresh": "Rafraichir",
    "newFolder": "Créer un dossier",
    "foldername": "Nom du dossier",
    "infos": "Informations",
    "folders": "Dossiers",
    "files": "Fichiers",
    "used": "Utilisé",
    "toast": {
      "title": "Erreur",
      "body": "Le fichier ne peut pas être téléversé car il est trop volumineux."
    },
    "confirm": "Êtes-vous sûr de vouloir supprimer ?",
    "orderBy": {
      "title": "Trier par",
      "name": "Nom",
      "date": "Date"
    }
  },
  "en": {
    "upload": "Upload a file",
    "drop": "Drop file here...",
    "refresh": "Refresh",
    "newFolder": "Create a folder",
    "foldername": "Folder name",
    "infos": "Information",
    "folders": "Folders",
    "files": "Files",
    "used": "Used",
    "toast": {
      "title": "Error",
      "body": "The file cannot be uploaded because its too large."
    },
    "confirm": "Are you sure you want to delete ?",
    "orderBy": {
      "title": "Order by",
      "name": "Name",
      "date": "Date"
    }
  }
}
</i18n>
