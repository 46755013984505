import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row no-gutters" }
const _hoisted_2 = {
  key: 0,
  class: "col-sm-12 col-md-6 col-lg-2"
}
const _hoisted_3 = {
  key: 0,
  class: "w-100"
}
const _hoisted_4 = { class: "col d-flex flex-column align-items-center" }
const _hoisted_5 = { class: "p-3 position-relative" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 1,
  class: "col-lg-auto col-md-12 p-3"
}
const _hoisted_8 = { class: "badge badge-light ml-auto" }
const _hoisted_9 = { class: "badge badge-light" }
const _hoisted_10 = {
  key: 0,
  class: "d-flex mt-2 ml-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_aspect = _resolveComponent("b-aspect")!
  const _component_b_link = _resolveComponent("b-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_list_group_item = _resolveComponent("b-list-group-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_list_group_item, {
      class: _normalizeClass(["p-0", !_ctx.widget ? 'shadow mb-3 border-0' : 'border mb-2'])
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.imageExist)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_ctx.article.thumbnail != 'null')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", {
                        style: _normalizeStyle(_ctx.style),
                        class: "rounded-left"
                      }, [
                        _createVNode(_component_b_aspect, { aspect: _ctx.aspect }, null, 8, ["aspect"])
                      ], 4)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_b_link, {
                href: "#",
                to: { name: 'Article', params: { id: _ctx.article.id } },
                class: "text-dark stretched-link"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1)
                ], undefined, true),
                _: 1
              }, 8, ["to"]),
              _createElementVNode("p", {
                class: "text-muted",
                innerHTML: _ctx.extractContent(_ctx.article.content)
              }, null, 8, _hoisted_6)
            ])
          ]),
          (!_ctx.widget)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_font_awesome_icon, {
                      class: "mr-1",
                      icon: [_ctx.$store.state.family, 'calendar-alt']
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$filters.calendar(_ctx.date)), 1)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_font_awesome_icon, {
                      class: "mr-1",
                      icon: [_ctx.$store.state.family, 'calendar-alt']
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.heure), 1)
                  ])
                ]),
                (_ctx.user.admin)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_b_button, {
                        to: { name: 'ArticleEdit', params: {id: _ctx.article.id} },
                        type: "submit",
                        variant: "warning",
                        class: "m-1 w-100"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('edit')), 1)
                        ], undefined, true),
                        _: 1
                      }, 8, ["to"]),
                      _createVNode(_component_b_button, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleted(_ctx.article.id))),
                        type: "submit",
                        variant: "danger",
                        class: "m-1"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_font_awesome_icon, {
                            icon: [_ctx.$store.state.family, 'trash-alt']
                          }, null, 8, ["icon"])
                        ], undefined, true),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ], undefined, true),
      _: 1
    }, 8, ["class"])
  ]))
}