// Import des modules
import admin from './modules/admin'
import session from './modules/session'
import folders from './modules/folders'
import explorer from './modules/explorer'
import user from './modules/user'
import patchnote from './modules/patchnote'
import patchnotemycarto from './modules/patchnotemycarto'
import dashboard from './modules/dashboard'
import tools from './modules/Tools'
import faq from './modules/faq'
import blog from './modules/blog'

// Export par défaut de l'instance d'API
export default {
  admin,
  session,
  folders,
  explorer,
  user,
  patchnote,
  patchnotemycarto,
  dashboard,
  tools,
  faq,
  blog
}
