import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/logo-white.png'
import _imports_1 from '@/assets/flags/english.png'
import _imports_2 from '@/assets/flags/french.png'


const _hoisted_1 = { class: "px-2" }
const _hoisted_2 = { class: "form-row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_img = _resolveComponent("b-img")!
  const _component_b_navbar_brand = _resolveComponent("b-navbar-brand")!
  const _component_b_navbar_toggle = _resolveComponent("b-navbar-toggle")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_nav_item = _resolveComponent("b-nav-item")!
  const _component_b_navbar_nav = _resolveComponent("b-navbar-nav")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown_divider = _resolveComponent("b-dropdown-divider")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_nav_item_dropdown = _resolveComponent("b-nav-item-dropdown")!
  const _component_b_collapse = _resolveComponent("b-collapse")!
  const _component_b_navbar = _resolveComponent("b-navbar")!

  return (_openBlock(), _createBlock(_component_b_navbar, {
    class: "border-bottom shadow-sm",
    type: "dark",
    variant: "dark",
    toggleable: "lg"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_navbar_brand, { to: { name: 'Home'} }, {
        default: _withCtx(() => [
          _createVNode(_component_b_img, {
            class: "mr-1",
            src: _imports_0,
            alt: "AB+ SOFTWARE",
            style: { height: '30px' }
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('app.name')), 1)
        ], undefined, true),
        _: 1
      }),
      _createVNode(_component_b_navbar_toggle, { target: "main-navbar-collapse" }),
      _createVNode(_component_b_collapse, {
        class: "w-100",
        id: "main-navbar-collapse",
        "is-nav": ""
      }, {
        default: _withCtx(() => [
          (_ctx.loggedIn)
            ? (_openBlock(), _createBlock(_component_b_navbar_nav, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_nav_item, {
                    to: { name: 'Home' },
                    "exact-active-class": "active"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: [_ctx.$store.state.family, 'home']
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('link.home')), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_b_nav_item, {
                    to: { name: 'Explorer', query: { path: ''} },
                    "active-class": "active"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: [_ctx.$store.state.family, 'folder']
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('link.explorer')), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  _createVNode(_component_b_nav_item, {
                    to: { name: 'Articles' },
                    "active-class": "active"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: [_ctx.$store.state.family, 'newspaper']
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('link.articles')), 1)
                    ], undefined, true),
                    _: 1
                  }),
                  (_ctx.user.company.product == 'SOLU-QIQ' || _ctx.user.admin)
                    ? (_openBlock(), _createBlock(_component_b_nav_item, {
                        key: 0,
                        to: { name: 'Home.Patchnote' },
                        "active-class": "active"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_font_awesome_icon, {
                            icon: [_ctx.$store.state.family, 'puzzle-piece']
                          }, null, 8, ["icon"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('link.patchnote')) + " " + _toDisplayString(_ctx.user.admin ? 'SOLU-QIQ' : ''), 1)
                        ], undefined, true),
                        _: 1
                      }, 8, ["to"]))
                    : _createCommentVNode("", true),
                  (_ctx.user.company.product == 'myCarto' || _ctx.user.admin)
                    ? (_openBlock(), _createBlock(_component_b_nav_item, {
                        key: 1,
                        to: { name: 'Home.PatchnoteMycarto' },
                        "active-class": "active"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_font_awesome_icon, {
                            icon: [_ctx.$store.state.family, 'puzzle-piece']
                          }, null, 8, ["icon"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('link.patchnote')) + " " + _toDisplayString(_ctx.user.admin ? 'myCarto' : ''), 1)
                        ], undefined, true),
                        _: 1
                      }, 8, ["to"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_b_nav_item, {
                    to: { name: 'Home.faq' },
                    "active-class": "active"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: [_ctx.$store.state.family, 'folder']
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('link.faq')), 1)
                    ], undefined, true),
                    _: 1
                  }, 8, ["to"]),
                  (_ctx.user.company.product == 'SOLU-QIQ')
                    ? (_openBlock(), _createBlock(_component_b_nav_item, {
                        key: 2,
                        to: { name: 'Home.mng' },
                        "active-class": "active"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_font_awesome_icon, {
                            icon: [_ctx.$store.state.family, 'code-branch']
                          }, null, 8, ["icon"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('link.mng')), 1)
                        ], undefined, true),
                        _: 1
                      }, 8, ["to"]))
                    : _createCommentVNode("", true)
                ], undefined, true),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_b_navbar_nav, { class: "ml-auto" }, {
            default: _withCtx(() => [
              (_ctx.loggedIn)
                ? (_openBlock(), _createBlock(_component_b_nav_item, {
                    key: 0,
                    variant: "outline-primary",
                    href: _ctx.getcontactUrl()
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: [_ctx.$store.state.family, 'envelope']
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('link.support')), 1)
                    ], undefined, true),
                    _: 1
                  }, 8, ["href"]))
                : _createCommentVNode("", true),
              _createVNode(_component_b_nav_item_dropdown, { right: "" }, {
                "button-content": _withCtx(() => [
                  (_ctx.loggedIn)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: [_ctx.$store.state.family, 'user-circle']
                        }, null, 8, ["icon"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.user.firstname), 1)
                      ], 64))
                    : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 1,
                        icon: [_ctx.$store.state.family, 'cog']
                      }, null, 8, ["icon"]))
                ]),
                default: _withCtx(() => [
                  (_ctx.loggedIn)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_b_dropdown_item, {
                          href: "#",
                          to: { name: 'Home.profile' }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, {
                              icon: [_ctx.$store.state.family, 'user-circle'],
                              "fixed-width": ""
                            }, null, 8, ["icon"]),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('link.profile')), 1)
                          ], undefined, true),
                          _: 1
                        }, 8, ["to"]),
                        _createVNode(_component_b_dropdown_item, {
                          href: "#",
                          onClick: _ctx.logout
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, {
                              icon: [_ctx.$store.state.family, 'sign-out-alt'],
                              "fixed-width": ""
                            }, null, 8, ["icon"]),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('link.logout')), 1)
                          ], undefined, true),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_b_dropdown_divider),
                        (_ctx.user.admin)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createVNode(_component_b_dropdown_item, {
                                to: { name: 'Admin' },
                                "active-class": "active"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_font_awesome_icon, {
                                    icon: [_ctx.$store.state.family, 'user-shield'],
                                    "fixed-width": ""
                                  }, null, 8, ["icon"]),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$t('link.admin')), 1)
                                ], undefined, true),
                                _: 1
                              }),
                              _createVNode(_component_b_dropdown_divider)
                            ], 64))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_b_button, {
                          variant: "white",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setLocale('en'))),
                          size: "sm",
                          block: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_img, {
                              src: _imports_1,
                              style: {width: '20px'}
                            })
                          ], undefined, true),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_b_button, {
                          variant: "white",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setLocale('fr'))),
                          size: "sm",
                          block: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_img, {
                              src: _imports_2,
                              style: {width: '20px'}
                            })
                          ], undefined, true),
                          _: 1
                        })
                      ])
                    ])
                  ])
                ], undefined, true),
                _: 1
              })
            ], undefined, true),
            _: 1
          })
        ], undefined, true),
        _: 1
      })
    ], undefined, true),
    _: 1
  }))
}