export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un fichier"])},
        "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposer le fichier ici..."])},
        "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafraichir"])},
        "newFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un dossier"])},
        "foldername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du dossier"])},
        "infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations"])},
        "folders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiers"])},
        "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers"])},
        "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisé"])},
        "toast": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier ne peut pas être téléversé car il est trop volumineux."])}
        },
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer ?"])},
        "orderBy": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])}
        }
      },
      "en": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a file"])},
        "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop file here..."])},
        "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
        "newFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a folder"])},
        "foldername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder name"])},
        "infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
        "folders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folders"])},
        "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
        "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used"])},
        "toast": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file cannot be uploaded because its too large."])}
        },
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete ?"])},
        "orderBy": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order by"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])}
        }
      }
    }
  })
}
