export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "link": {
          "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
          "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
          "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
          "explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorateur"])},
          "patchnote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes de version"])},
          "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
          "mng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple de cartographie"])},
          "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez le support"])},
          "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profil"])}
        }
      },
      "en": {
        "link": {
          "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
          "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
          "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
          "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
          "explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorer"])},
          "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles"])},
          "patchnote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patchnote"])},
          "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q&A"])},
          "mng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartography example"])},
          "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact support"])},
          "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])}
        }
      }
    }
  })
}
