<template>
  <div id="app" class="d-flex flex-column" :style="{minHeight: '100vh'}">
    <div class="navbar-container">
      <main-navbar />
    </div>
    <div class="content-container flex-grow-1">
      <router-view/>
    </div>
    <div class="footer-container">
      <main-footer />
    </div>
  </div>
</template>

<script lang="ts">

import { mapState } from 'vuex'
import MainNavbar from '@/components/MainNavbar.vue'
import MainFooter from '@/components/MainFooter.vue'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  computed: {
    ...mapState('locales', ['locale'])
  },
  watch: {
    locale (value) {
      this.$i18n.locale = value
      this.$moment.locale(value)
    }
  },
  created () {
    this.$i18n.locale = this.locale
    this.$moment.locale(this.locale)
  },
  components: {
    MainNavbar,
    MainFooter
  }
})
</script>
