<template>
  <div>

    <div class="container">
      <div class="row">

        <div class="col-4">
          <b-list-group>
            <b-list-group-item
              @click="getpatchnote(item)"
              v-for="(item, index) in versionList"
              :key="index" button
              class="d-flex align-items-center"
              active-class="bg-dark"
              :class="item.version == versionSelect ? 'active' : ''">

                {{item.name}}
                <span class="ml-auto badge" :class="item.version == versionSelect ? 'badge-white' : 'badge-dark'">{{item.date}}</span>

            </b-list-group-item>
          </b-list-group>

          <div class="mt-4" v-if="filesInfo">
            <div class="mb-3">
              <div class="badge badge-primary">Documentation</div>
              <b-list-group>

                <b-list-group-item
                  v-if="filesInfo.documentation.preRequis"
                  button
                  @click="showFile(filesInfo.documentation.preRequis)"
                  class="d-flex align-items-center justify-content-between">
                  {{ $t('preRequisite') }}
                  <div class="badge badge-red ml-2">PDF</div>
                </b-list-group-item>

                <b-list-group-item
                  v-if="filesInfo.documentation.avantMigration"
                  button
                  @click="showFile(filesInfo.documentation.avantMigration)"
                  class="d-flex align-items-center justify-content-between">
                  {{ $t('beforeConsult') }}
                  <div class="badge badge-red ml-2">PDF</div>
                </b-list-group-item>

                <b-list-group-item
                  v-if="filesInfo.documentation.informationParVersion"
                  button
                  @click="showFile(filesInfo.documentation.informationParVersion)"
                  class="d-flex align-items-center justify-content-between">
                  {{ $t('importantInfo') }}
                  <div class="badge badge-red ml-2">PDF</div>
                </b-list-group-item>

              </b-list-group>
            </div>

            <div class="mb-3" v-if="user.admin || (user.company.client && user.company.deployment == 'On_Premise')">
              <div class="badge badge-dark">{{ $t('install') }} SOLU-QIQ Base</div>
              <b-list-group>

                <b-list-group-item
                  v-if="filesInfo.base.installation"
                  button
                  @click="showFile(filesInfo.base.installation)"
                  class="d-flex align-items-center justify-content-between">
                  {{ $t('installOf') }} SOLU-QIQ Base
                  <div class="badge badge-info ml-2">EXE</div>
                </b-list-group-item>

                <b-list-group-item
                  v-if="filesInfo.base.installation"
                  button
                  @click="showFile('Installation de SOLU-QIQ Base.pdf')"
                  class="d-flex align-items-center justify-content-between">
                  {{ $t('install') }} SOLU-QIQ Base
                  <div class="badge badge-danger ml-2">PDF</div>
                </b-list-group-item>

              </b-list-group>
            </div>

            <div class="mb-3" v-if="user.admin || (user.company.client && user.company.deployment == 'On_Premise')">
              <div class="badge badge-dark">{{ $t('update') }} SOLU-QIQ Base</div>
              <b-list-group>

                <b-list-group-item
                  v-if="filesInfo.base.patch"
                  button
                  @click="showFile(filesInfo.base.patch)"
                  class="d-flex align-items-center justify-content-between">
                  {{ $t('lastPatchFor') }} SOLU-QIQ Base
                  <div class="badge badge-info ml-2">EXE</div>
                </b-list-group-item>

                <b-list-group-item
                  v-if="filesInfo.base.installation"
                  button
                  @click="showFile('Mise à jour de SOLU-QIQ Base.pdf')"
                  class="d-flex align-items-center justify-content-between">
                  {{ $t('update') }} SOLU-QIQ Base
                  <div class="badge badge-danger ml-2">PDF</div>
                </b-list-group-item>

              </b-list-group>
            </div>

            <div class="mb-3" v-if="user.admin || (user.company.client && user.company.deployment == 'On_Premise')">
              <div class="badge badge-dark">{{ $t('install') }} - {{ $t('update') }} SOLU-QIQ Web Contributeur</div>
              <b-list-group>

                <b-list-group-item
                  v-if="filesInfo.web.installation"
                  button
                  @click="showFile(filesInfo.web.installation)"
                  class="d-flex align-items-center justify-content-between">
                  {{ $t('installOf') }} SQWeb Contributeur
                  <div class="badge badge-warning ml-2">ZIP</div>
                </b-list-group-item>

                <b-list-group-item
                  v-if="filesInfo.web.installation"
                  button
                  @click="showFile('Déploiement de SOLU-QIQ Web Contributeur.pdf')"
                  class="d-flex align-items-center justify-content-between">
                  {{ $t('install') }} - {{ $t('update') }} SQWeb Contributeur
                  <div class="badge badge-danger ml-2">PDF</div>
                </b-list-group-item>

              </b-list-group>
            </div>

            <div class="mb-3" v-if="user.admin || (user.company.client && user.company.deployment == 'On_Premise')">
              <div class="badge badge-dark">{{ $t('updateSQServices') }}</div>
              <b-list-group>

                <b-list-group-item
                  v-if="filesInfo.services.installationSQAuto"
                  button
                  @click="showFile(filesInfo.services.installationSQAuto)"
                  class="d-flex align-items-center justify-content-between">
                  {{ $t('installSQAuto') }}
                  <div class="badge badge-warning ml-2">ZIP</div>
                </b-list-group-item>

                <b-list-group-item
                  v-if="filesInfo.services.installationGrapheAuto"
                  button
                  @click="showFile(filesInfo.services.installationGrapheAuto)"
                  class="d-flex align-items-center justify-content-between">
                  {{ $t('installGA') }}
                  <div class="badge badge-warning ml-2">ZIP</div>
                </b-list-group-item>


                <b-list-group-item
                  v-if="filesInfo.web.installation"
                  button
                  @click="showFile('Installation et mise à jour des services SOLU-QIQ.pdf')"
                  class="d-flex align-items-center justify-content-between">
                  Installation et mise à jour des services SOLU-QIQ
                  <div class="badge badge-danger ml-2">PDF</div>
                </b-list-group-item>

              </b-list-group>
            </div>

            <div class="mb-3" v-if="filesInfo.API.installation && (user.admin || (user.company.client && user.company.deployment == 'On_Premise'))">
              <div class="badge badge-dark">{{ $t('updateWAPI') }} :</div>
              <b-list-group>

                <b-list-group-item
                  @click="showFile(filesInfo.API.installation)"
                  button
                  class="d-flex align-items-center justify-content-between">
                  {{ $t('installOf') }} SOLU-QIQ Web API
                  <div class="badge badge-warning ml-2">ZIP</div>
                </b-list-group-item>

                <b-list-group-item
                  @click="showFile('Déploiement de SOLU-QIQ Web API.pdf')"
                  button
                  class="d-flex align-items-center justify-content-between">
                  {{ $t('install') }} SOLU-QIQ Web API
                  <div class="badge badge-danger ml-2">PDF</div>
                </b-list-group-item>

              </b-list-group>
            </div>

          </div>
        </div>
        <div class="col-8">

          <b-alert
            v-if="Patchnote.commentaire"
            variant="warning"
            show
          >
            {{ Patchnote.commentaire }}
          </b-alert>

          <b-nav class="custom-nav-pills custom-nav-pills-s rounded mb-3" pills justified>
            <b-nav-item :active="'versions_SQBase' == mode ? true : false" @click="setMode('versions_SQBase')">
              SOLU-QiQ Base
            </b-nav-item>
            <b-nav-item :active="'versions_SQWeb' == mode ? true : false" @click="setMode('versions_SQWeb')">
              SOLU-QiQ Web Contributeur
            </b-nav-item>
          </b-nav>

          <div>
            <b-card no-body class="mb-1" v-for="(item, index) in PatchnoteMode" :key="index">
              <b-card-header  header-tag="header" class="p-1 " role="tab">
                <b-button class="d-flex align-items-center" href="#" show v-b-toggle="'accordion-' + index"  variant="light">
                  {{item.version}}
                  <span class="ml-auto badge badge-dark">{{item.date}}</span>
                </b-button>
              </b-card-header>
              <b-collapse :id="'accordion-' + index" :visible="index == 0 ? true : false" :accordion="'accordion-' + index" role="tabpanel">
                <b-card-body>

                  <div v-for="(type, typeIndex) in item.historique" :key="typeIndex">
                    <div class="ml-auto mb-1 p-2 rounded " :class="typeIndex ==  'correction' ? 'bg-light ' : 'bg-light'" v-for="(fix, index) in type" :key="index">

                      <div class="d-flex">
                        <span class="mr-1 badge" :class="typeIndex ==  'correction' ? 'badge-dark' : 'badge-success'">{{typeIndex ==  'correction' ? 'Ticket' : 'Evol'}}</span>
                        <span class="mr-1 badge badge-primary" v-for="(ticket, ticketIndex) in fix.ticket" :key="ticketIndex">{{ticket}}</span>
                        <span class="ml-auto badge badge-secondary">{{item.date}}</span>
                      </div>
                      <template v-if="typeIndex ==  'correction'">
                        {{fix.text}}
                      </template>
                      <template v-else>
                        {{fix}}
                      </template>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script  lang="ts">
import api from '@/api'

interface Item {
  version: string;
  name: string;
  files: null|string[];
}

import { defineComponent } from 'vue'
import { mapState } from 'vuex'

export default defineComponent({
  data(){
    return {
      versionList: [],
      Patchnote: [],
      PatchnoteMode: [],
      mode: 'versions_SQBase',
      versionSelect: '',
      versionName: '',
      filesInfo: null as null|string[],
      baseFileDownload: 'https://soluqiq.fr/download/'
    }
  },
    computed: {
    ...mapState('auth', ['loggedIn', 'user']),
    ...mapState(['family'])
  },
  created () {
    api.patchnote.getListVersion()
      .then(response => response.data)
      .then(data => {
        if (data.status) {
          this.versionList = data.results.reverse()
          this.getpatchnote(this.versionList[0])
          this.filesInfo = this.versionList[0]['files']
        }
      })
  },
  methods:{
    getpatchnote(item: Item)  {
      api.patchnote.getPatchnote(item.version)
      .then(response => response.data)
      .then(data => {
        if (data.status) {
          this.versionName = item.name
          this.versionSelect = item.version
          this.Patchnote = data.results
          this.PatchnoteMode =  this.Patchnote[this.$data.mode];
          this.filesInfo = item['files']
        }
      })
    },
    setMode(mode: string){
      this.$data.mode = mode
      this.PatchnoteMode =  this.Patchnote[this.$data.mode];
    },
    showFile(fileName: string){
      const url = this.baseFileDownload + this.versionSelect + '/' + fileName
      window.open(url,'_blank');
    },
  }
})
</script>

<i18n>
{
  "fr": {
    "preRequisite": "Pré-requis",
    "beforeConsult": "À consulter avant toute migration",
    "importantInfo": "Informations importantes par version",
    "update": "Mettre à jour",
    "installOf": "Installation complete",
    "install": "Installer",
    "lastPatchFor": "Patch ",
    "updateSQServices": "Installer et mettre à jour les services SOLU-QIQ",
    "installGA": "Installation du service Graphes Auto",
    "updateWAPI": "Mettre à jour SOLU-QIQ Web API",
    "installSQAuto": "Installation du service SQAuto"
  },
  "en": {
    "preRequisite": "Pre-requisites",
    "beforeConsult": "To consult before any migration",
    "importantInfo": "Important information by version",
    "update": "Update",
    "installOf": "Install of",
    "install": "Installation",
    "lastPatchFor": "Last patch for",
    "updateSQServices": "Install and update SOLU-QIQ services",
    "installGA": "Install of Graphes Auto service",
    "updateWAPI": "Update SOLU-QIQ Web API",
    "installSQAuto": "Install of SQAuto service"
  }
}
</i18n>
