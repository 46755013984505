import instance from '@/api/instance'

interface Faq{
  question: string;
  reponse: string;
  cp: number;
}

interface UpdateFaq{
  id: number;
  question: string;
  reponse: string;
  cp: number;
}

export default {
  getFaq: () => {
    return instance.get('/admin/faq')
  },
  add: (faq: Faq) => {
    return instance.post('/admin/faq', {faq})
  },
  update: (faq: UpdateFaq) => {
    return instance.post('/admin/faq/update', {faq})
  },
  delete: (id: number) => {
    return instance.delete('/admin/faq/delete', {
      params: {
        id: id
      }
    })
  },
}
