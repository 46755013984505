import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "py-1" }
const _hoisted_2 = { class: "text-muted d-flex justify-content-between align-items-end" }
const _hoisted_3 = {
  class: "font-weight-bold text-truncate pr-2",
  style: { minWidth: 0 }
}
const _hoisted_4 = {
  key: 0,
  class: "text-success"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_progress = _resolveComponent("b-progress")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("small", _hoisted_3, _toDisplayString(_ctx.file.name), 1),
      (_ctx.done)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
            _createVNode(_component_font_awesome_icon, {
              icon: [_ctx.$store.state.family, 'check']
            }, null, 8, ["icon"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_b_progress, {
      value: _ctx.progress,
      height: ".4rem"
    }, null, 8, ["value"])
  ]))
}