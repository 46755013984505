<template>
  <div>
    <h3 class="font-weight-bold d-flex justify-content-between mb-5">
      {{$t('articleTitle')}}

      <div class="d-flex align-items-center">
        <b-form-input class="mr-2" v-model="filter" :placeholder="$t('search')"></b-form-input>
        <div v-if="user.admin" class="text-right">
          <b-button
            variant="primary"
            :to="{ name: 'NewArticle' }"
          >
            {{$t('addArticle')}}
          </b-button>
        </div>
      </div>
    </h3>
    <articles-list :filter="filter" />
  </div>
</template>

<script  lang="ts">
import { mapState } from 'vuex'
import ArticlesList from './ArticlesList.vue'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    ArticlesList
  },
  data () {
    return {
      filter: null
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  }
})
</script>

<i18n>
  {
    "fr": {
      "articleTitle": "Avancée du développement",
      "addArticle": "Ajouter",
      "search": "Chercher un article"
    },
    "en": {
      "articleTitle": "Development progress",
      "addArticle": "Add",
      "search": "Search article"
    }
  }
</i18n>
